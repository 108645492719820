import * as React from 'react';
import { SessionLock, SessionLockParams } from 'models/sessionLock';
import useSWR from 'swr';
import config from 'config';
import axios from 'axios';
import { getUserId } from 'lib/util/getUserId';
import { ApiKeyContext } from 'contexts/ApiKeyContext';

interface Props {
  resourceId: string;
}

const fetcher = async (apiKey: string, req: SessionLockParams) => {
  if (req.client_id === '' || req.resource_id === '') {
    return null;
  }

  if (!config.enableETicketFraudDetection) {
    return null;
  }

  try {
    const res = await axios.post(`${config.apiUrl}/sessionlock`, req, {
      headers: { 'x-api-key': apiKey },
    });
    return res.data;
  } catch (error: any) {
    if (error.response) {
      // ステータスコードが 200-299 の範囲外の場合、エラーを投げます。
      throw new Error('An error occurred while fetching the data.');
    } else {
      // ネットワークエラーの場合、エラーを投げます。
      throw new Error('Network Error');
    }
  }
};

export const useSessionLock = ({ resourceId }: Props) => {
  const [isLocked, setSessionLock] = React.useState<boolean>(false);
  const [clientId, setClientId] = React.useState<string>('');

  const { apiKey } = React.useContext(ApiKeyContext);

  React.useEffect(() => {
    const useId = getUserId();
    if (useId) {
      setClientId(useId);
    }
  }, []);

  const { data, error } = useSWR<SessionLock | null>(
    [
      apiKey,
      {
        resource_id: resourceId,
        client_id: clientId,
      },
    ],
    fetcher,
    {
      refreshInterval: 5000,
    }
  );

  React.useEffect(() => {
    if (data?.client_id === clientId) {
      setSessionLock(true);
    } else {
      setSessionLock(false);
    }
  }, [data]);

  return { isLocked, error };
};
