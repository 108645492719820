import * as React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress, Box } from '@mui/material';
import { leaveFootprint } from 'ducks/client/footprints';
import { ApiKeyContext } from 'contexts/ApiKeyContext';
import { checkinReservation } from 'ducks/client/reservation';
import { Reservation } from 'models/reservation';
import { Product } from 'models/product';
import { ReduxState } from 'ducks';
import { CurrentPositionContext } from 'contexts/CurrentPositionContext';
import { GuestTypeCount } from 'contexts/ETicketRedeemTargetContext';
import { useETicketRedemptionStatus } from 'hooks/useETicketRedemptionStatus';
import { formatTextWithLineBreaks } from 'lib/util/formatTextWithLineBreaks';
import config from 'config';
import { ETicketFieldResponsePane } from 'components/ETicketFieldResponsePane/ETicketFieldResponsePane';
import { logCustomerEventWithNoSideEffects } from 'ducks/client/customerEvents';
import { CustomerContext } from 'contexts/CustomerContext';

import styles from './ETicketCheckinModal.module.css';

// Show checkin modal
export const ETicketCheckinModal = ({
  reservation,
  open,
  onClose,
  onSuccessfulCheckin,
  stubKey,
  initialStubOptionKey,
  guestTypeCounts,
  redemptionCount,
  product,
  presetRedemptionCountKey,
  onPresetRedemptionCountChange,
}: {
  reservation: Reservation;
  open: boolean;
  onClose: () => void;
  onSuccessfulCheckin: () => void;
  stubKey?: string;
  initialStubOptionKey?: string;
  guestTypeCounts?: GuestTypeCount[];
  redemptionCount?: number;
  product: Product | undefined;
  presetRedemptionCountKey?: string;
  onPresetRedemptionCountChange?: (presetRedemptionCountKey: string) => void;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const modalRef = React.useRef<HTMLDivElement>(null);
  const [stubOptionKey, setStubOptionKey] = React.useState<string>(initialStubOptionKey ?? '');
  const { apiKey } = React.useContext(ApiKeyContext);
  const { i18n } = useTranslation();
  const { currentPosition } = React.useContext(CurrentPositionContext);
  const [showErrorMessage, setShowErrorMessage] = React.useState<boolean>(false);
  const [afterClickRedemptionButton, setAfterClickRedemptionButton] = React.useState<boolean>(
    false
  );
  const [cancelButtonLoading, setCancelButtonLoading] = React.useState<boolean>(false);
  const visitorToken = useSelector((state: ReduxState) => state.tracking.visitorToken);
  const { customer } = React.useContext(CustomerContext);
  const settings = useSelector((state: ReduxState) => state.server.settings.all);

  const inFlight = useSelector((state: ReduxState) => state.reservation.inFlight);
  const error = useSelector((state: ReduxState) => state.reservation.error);
  const checkinStatusloading = useSelector(
    (state: ReduxState) => state.reservation.reservationCheckinStatusLoading
  );

  const stubText = React.useMemo(() => {
    const stub = product?.qr_checkin_settings?.stubs?.find((stub) => stub.key === stubKey);
    return stub?.text;
  }, [stubKey, product]);

  const totalRedemptionGuestCount =
    (guestTypeCounts || []).reduce((acc, guestTypeCount) => {
      return acc + guestTypeCount.count;
    }, 0) || 0;

  const maxRedemptionCount =
    (stubKey
      ? product?.qr_checkin_settings?.stubs?.find((s) => s.key === stubKey)?.max_redemption_count
      : product?.qr_checkin_settings?.max_redemption_count) || 0;
  const totalExistingRedemptionCount = (reservation?.checkin_info?.checkin_records ?? []).reduce(
    (acc, record) => {
      if (stubKey) {
        if (record.stub_key !== stubKey) return acc;
      }
      return acc + (record.redemption_count ?? 0);
    },
    0
  );

  const remainingRedemptionCountAfterRedemption =
    maxRedemptionCount - totalExistingRedemptionCount - (redemptionCount ?? 0);

  const redemptionErrorWithGuestTypeCount = totalRedemptionGuestCount !== 0 && showErrorMessage;

  const closeHandler = () => {
    if (redemptionErrorWithGuestTypeCount) {
      window.location.reload();
      setCancelButtonLoading(true);
    } else {
      onClose();
    }
  };

  React.useEffect(() => {
    const onWindowClick = (event: MouseEvent | TouchEvent) => {
      if (modalRef && !modalRef.current?.contains(event.target as Node)) {
        closeHandler();
      }
    };

    window.addEventListener('mousedown', onWindowClick);

    return () => {
      window.removeEventListener('mousedown', onWindowClick);
    };
  }, [showErrorMessage]);

  const { reason } = useETicketRedemptionStatus(reservation, product ?? null);

  React.useEffect(() => {
    if (inFlight || !afterClickRedemptionButton) return;

    if (error) {
      setShowErrorMessage(true);
    } else {
      onSuccessfulCheckin();
      onClose();
    }

    setAfterClickRedemptionButton(false); // reset after handling
  }, [inFlight, error]);

  React.useEffect(() => {
    if (presetRedemptionCountKey) {
      return;
    }
    if (stubOptions?.length) {
      setStubOptionKey(stubOptions[0].value);
    }
    if (presetRedemptionCountOptions?.length) {
      onPresetRedemptionCountChange?.(presetRedemptionCountOptions[0].value);
    }
  }, []);

  const stubOptions = product?.qr_checkin_settings?.stubs
    ?.find((stub) => stub.key === stubKey)
    ?.options?.map((option) => {
      return {
        value: option.key,
        text: option.text,
      };
    });

  const noteForRedemptionModal = product?.qr_checkin_settings?.note_for_redemption_modal;

  const handleStubOptionChange = (event: React.ChangeEvent<HTMLSelectElement>) =>
    setStubOptionKey(event.target.value);

  const handlePresetRedemptionCountChange = (event: React.ChangeEvent<HTMLSelectElement>) =>
    onPresetRedemptionCountChange?.(event.target.value);

  const computeGuestCount = (): number => {
    if (guestTypeCounts?.length) {
      return guestTypeCounts.reduce((acc, guestTypeCount) => acc + guestTypeCount.count, 0);
    }
    return reservation.guests.length;
  };

  const prepareGuestTypeCountsForDispatch = () => {
    return (guestTypeCounts || []).map((guestTypeCount) => ({
      guest_type_key: guestTypeCount.guestTypeKey,
      count: guestTypeCount.count,
    }));
  };

  let checkinApiKey = apiKey;
  if (config.enablePartnershipPackageReservation) {
    checkinApiKey =
      settings.is_partnership_hub_supplier && reservation.supplier_id !== settings.supplier_id
        ? settings.corresponding_organization_booking_widget_api_key ?? apiKey
        : apiKey;
  }

  const presetRedemptionCountOptions = product?.qr_checkin_settings?.preset_redemption_counts?.map(
    (presetRedemptionCount) => {
      return {
        value: presetRedemptionCount.key,
        text: t('{{title}} (use {{count}} tickets)', {
          title: presetRedemptionCount.text,
          count: presetRedemptionCount.redemption_count,
        }),
      };
    }
  );

  const checkinHandler = async () => {
    logCustomerEventWithNoSideEffects({
      apiKey: checkinApiKey,
      eventType: 'eticket-redeem',
      visitorToken,
      reservationId: reservation.id,
      metadata: JSON.stringify({
        lat: currentPosition?.lat(),
        lng: currentPosition?.lng(),
        site: 'onsite',
        stub_key: stubKey,
        stub_option_key: stubOptionKey,
      }),
      customerId: customer?.id ?? '',
    });
    const promises = [
      dispatch(
        checkinReservation(
          checkinApiKey,
          reservation?.id || '',
          computeGuestCount(),
          '',
          '',
          stubKey || '',
          stubOptionKey || '',
          prepareGuestTypeCountsForDispatch(),
          redemptionCount || 0,
          presetRedemptionCountKey || null,
          currentPosition?.lat() ?? null,
          currentPosition?.lng() ?? null,
          i18n.language
        )
      ),
    ];
    if (config.enableLeaveFootprintWithoutStub || stubKey) {
      promises.push(
        dispatch(
          leaveFootprint(checkinApiKey, {
            reservation_id: reservation.id,
            latitude: currentPosition?.lat() ?? 0,
            longitude: currentPosition?.lng() ?? 0,
            stub_key: stubKey,
          })
        )
      );
    }

    try {
      setShowErrorMessage(false);
      setAfterClickRedemptionButton(true);
      await Promise.all(promises);
    } catch (e) {
      console.log(e);
    }
  };

  const isRedeemButtonDisabled =
    inFlight ||
    ((presetRedemptionCountOptions || []).length > 0 &&
      remainingRedemptionCountAfterRedemption < 0);

  return (
    <div className={clsx(styles['modal'], open ? styles['is-active'] : null)}>
      <div className={styles['modal__inner']}>
        <div className={styles['modal__content']}>
          <div ref={modalRef} className={styles['c-review__modal__content']}>
            <div className={styles['modal__content__memo']}>
              <p className={styles['modal__content__memo__item']}>{t('Confirmation')}</p>
              {stubText ?? <p className={styles['modal__content__memo__item']}>{stubText}</p>}
            </div>
            {checkinStatusloading && config.enableReservationCheckinStatus ? (
              <Box width="100%">
                <Box width="100%" display="flex" justifyContent="center">
                  <CircularProgress />
                </Box>
              </Box>
            ) : (
              <>
                {(guestTypeCounts || []).length > 0 && (
                  <>
                    <p className={styles['modal__content__number']}>
                      {(guestTypeCounts || [])
                        .filter((guestTypeCount) => guestTypeCount.count > 0)
                        .map((guestTypeCount, index) => (
                          <>
                            {guestTypeCount.guestTypeTitle} : {guestTypeCount.count}
                            {index !== (guestTypeCounts || []).length - 1 && <br />}
                          </>
                        ))}
                    </p>
                    <div className={styles['modal__content__summary']}>
                      <p>{t('Total')}</p>
                      <p>
                        <span>{totalRedemptionGuestCount}</span>
                        {t('people')}
                      </p>
                    </div>
                  </>
                )}
                {(redemptionCount ?? 0) > 0 && (
                  <>
                    <p className={styles['modal__content__number']}>
                      {t('Tickets to redeem')}：{redemptionCount ?? 0}
                    </p>
                    {remainingRedemptionCountAfterRedemption >= 0 ? (
                      <div className={styles['modal__content__redemption_count__summary']}>
                        <p>
                          <span className={styles['title']}>{t('Already redeemed')}</span>：
                          <span className={styles['number']}>{totalExistingRedemptionCount}</span>
                        </p>
                        <p>
                          <span className={styles['title']}>{t('Remaining after redemption')}</span>
                          ：
                          <span className={styles['number']}>
                            {maxRedemptionCount -
                              totalExistingRedemptionCount -
                              (redemptionCount ?? 0)}
                          </span>
                        </p>
                      </div>
                    ) : (
                      <div
                        className={clsx(
                          styles['modal__content__redemption_count__summary'],
                          styles['redemption_count__summary--shortage']
                        )}
                      >
                        <p>
                          <span className={styles['title']}>{t('Shortage')}</span>：
                          <span className={styles['number']}>
                            {-1 * remainingRedemptionCountAfterRedemption}
                          </span>
                        </p>
                      </div>
                    )}
                  </>
                )}
                {reservation && product?.annual_pass_mode_settings?.is_enabled && (
                  <ETicketFieldResponsePane reservation={reservation} product={product} />
                )}
                <>
                  <p className={clsx(styles['modal__content__txt'], styles['center'])}>
                    {reason ? (
                      <>{reason}</>
                    ) : (
                      <>
                        {noteForRedemptionModal ? (
                          <p className={clsx(styles['custom_note'])}>{noteForRedemptionModal}</p>
                        ) : (
                          <>
                            {t('You are about to redeem ticket.')}
                            <br />
                            {t('Click "Redeem" to proceed')}
                          </>
                        )}
                      </>
                    )}
                  </p>
                </>
                {redemptionErrorWithGuestTypeCount && (
                  <p
                    className={clsx(
                      styles['modal__content__txt'],
                      styles['center'],
                      styles['checkin_modal__note']
                    )}
                  >
                    {t(
                      'Some of the tickets have been redeemed already and we were not able to redeem tickets for {{guestCount}}. Please reselect the number of guests.',
                      {
                        guestCount: totalRedemptionGuestCount,
                      }
                    )}
                  </p>
                )}

                {stubOptions?.length && !reason ? (
                  <div className={clsx(styles['checkin_modal__option'])}>
                    {/* <p className={styles['checkin_modal__label']}>
                      {stubOptions.find((o) => o.value === stubOptionKey)?.text}
                    </p> */}
                    <label>
                      <select value={stubOptionKey} onChange={handleStubOptionChange}>
                        {stubOptions?.map((stubOption) => (
                          <option key={stubOption.value} value={stubOption.value}>
                            {stubOption.text}
                          </option>
                        ))}
                      </select>
                    </label>
                  </div>
                ) : null}

                {config.enableETicketPresetRedemptionCount &&
                presetRedemptionCountOptions?.length ? (
                  <div className={clsx(styles['checkin_modal__option'])}>
                    <label>
                      <select
                        value={presetRedemptionCountKey}
                        onChange={handlePresetRedemptionCountChange}
                      >
                        {presetRedemptionCountOptions?.map((presetRedemptionCount) => (
                          <option
                            key={presetRedemptionCount.value}
                            value={presetRedemptionCount.value}
                          >
                            {presetRedemptionCount.text}
                          </option>
                        ))}
                      </select>
                    </label>
                  </div>
                ) : null}

                <>
                  {!reason && (
                    <div className={styles['modal__content__btns']}>
                      {!redemptionErrorWithGuestTypeCount && (
                        <button
                          className={clsx(
                            styles['btn'],
                            styles['btn--submit'],
                            styles['btn--redeem'],
                            isRedeemButtonDisabled ? styles['btn--redeem--disabled'] : null
                          )}
                          onClick={() => {
                            checkinHandler();
                          }}
                          disabled={isRedeemButtonDisabled}
                          style={{
                            ...(product?.qr_checkin_settings?.redemption_button_color &&
                            !isRedeemButtonDisabled
                              ? {
                                  backgroundColor:
                                    product?.qr_checkin_settings?.redemption_button_color,
                                }
                              : {}),
                            ...(product?.qr_checkin_settings?.redemption_button_text_color &&
                            !isRedeemButtonDisabled
                              ? {
                                  color: product?.qr_checkin_settings?.redemption_button_text_color,
                                }
                              : {}),
                          }}
                        >
                          {inFlight && (
                            <Box width="100%" position="absolute">
                              <Box width="100%" display="flex" justifyContent="center">
                                <CircularProgress />
                              </Box>
                            </Box>
                          )}
                          {product?.qr_checkin_settings?.redemption_button_text
                            ? formatTextWithLineBreaks(
                                product?.qr_checkin_settings?.redemption_button_text
                              )
                            : t('Redeem')}
                        </button>
                      )}
                      <button
                        className={clsx(styles['btn'], styles['btn--cancel'])}
                        disabled={cancelButtonLoading}
                        onClick={() => {
                          closeHandler();
                        }}
                      >
                        {cancelButtonLoading && (
                          <Box width="100%" position="absolute">
                            <Box width="100%" display="flex" justifyContent="center">
                              <CircularProgress />
                            </Box>
                          </Box>
                        )}
                        {t('Close')}
                      </button>
                    </div>
                  )}
                </>
              </>
            )}
          </div>
        </div>
      </div>
      <div className={styles['modal__overlay']}></div>
    </div>
  );
};
