import * as React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import moment from 'moment-timezone';

import { Reservation } from 'models/reservation';
import { getGuestNameText } from 'components/Checkin/utils';
import { BarCode } from 'components/BarCode/BarCode';
import { Product } from 'models/product';

import styles from './FootprintedModal.module.css';

interface Props {
  reservation: Reservation;
  product: Product;
  onClose: () => void;
  open: boolean;
}

export const FootprintedModal = ({ reservation, product, onClose, open }: Props) => {
  const { t } = useTranslation();
  const modalRef = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    const onWindowClick = (event: MouseEvent | TouchEvent) => {
      if (modalRef && !modalRef.current?.contains(event.target as Node)) {
        onClose();
      }
    };

    window.addEventListener('mousedown', onWindowClick);

    return () => {
      window.removeEventListener('mousedown', onWindowClick);
    };
  }, []);

  const now = moment().tz(reservation?.start_timezone ?? 'UTC');

  const barCode = product?.qr_checkin_settings?.bar_code ?? '';

  return (
    <>
      <div className={clsx(styles['slideUp'], open ? styles['is-active'] : null)}>
        <div className={clsx(styles['slideUp__content'], barCode ? styles['barcode'] : null)}>
          <div className={clsx(styles['box'])}>
            <div className={clsx(styles['wave'], styles['wave-one'])}></div>
            <div className={clsx(styles['wave'], styles['wave-two'])}></div>
            <div className={clsx(styles['wave'], styles['wave-three'])}></div>
          </div>
          <p className={clsx(styles['slideUp__content__close'])}></p>
          <div className={clsx(styles['slideUp__content__ttl'])}>
            {now.format('YYYY/MM/DD HH:mm')}
          </div>
          {barCode && (
            <div className={clsx(styles['slideUp__content__ttl'])}>
              <BarCode value={barCode} />
            </div>
          )}
          <ul className={clsx(styles['slideUp__content__list'])}>
            <li className={clsx(styles['slideUp__content__list__item'])}>
              <p className={clsx(styles['slideUp__content__list__item__ttl'])}>{t('Name')}</p>
              <p className={clsx(styles['slideUp__content__list__item__body'])}>
                {getGuestNameText(reservation, t)}
              </p>
            </li>
            <li className={clsx(styles['slideUp__content__list__item'])}>
              <p className={clsx(styles['slideUp__content__list__item__ttl'])}>
                {t('Booking reference')}
              </p>
              <p className={clsx(styles['slideUp__content__list__item__body'])}>
                {reservation.supplier_reference}
              </p>
            </li>
          </ul>
        </div>
        <div className={clsx(styles['slideUp__overlay'])}></div>
      </div>
      <div className={clsx(styles['slideUpBottom'], open ? styles['is-active'] : null)}></div>
    </>
  );
};
