import { Reservation } from 'models/reservation';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Product } from 'models/product';
import { getCheckinStatus } from 'lib/util/getCheckinStatus';
import { useTimer, useTime } from 'react-timer-hook';

interface Props {
  reservation: Reservation;
  product: Product;
}

import styles from './ETicketPage.module.css';

export const ExpirationDateTime = ({ reservation, product }: Props) => {
  const checkinStatus = getCheckinStatus(reservation);
  return (
    <>
      {reservation.free_start_date_time_from ? (
        <>
          {checkinStatus === 'started' ? (
            <StartedFreePass reservation={reservation} product={product} />
          ) : (
            <NotStartedFreePass reservation={reservation} product={product} />
          )}
        </>
      ) : (
        <>
          {checkinStatus === 'started' ? (
            <>
              {reservation.is_annual_pass_mode ? (
                <StartedAnnualPass reservation={reservation} product={product} />
              ) : (
                <StartedNormal reservation={reservation} product={product} />
              )}
            </>
          ) : (
            <NotStartedNormal reservation={reservation} product={product} />
          )}
        </>
      )}
    </>
  );
};

const StartedNormal = ({ reservation, product }: Props) => {
  const { t } = useTranslation();

  const isEtarnal =
    product?.qr_checkin_settings?.expiration_date?.relative_date?.type === 'ETERNAL';

  return (
    <>
      {isEtarnal ? (
        <div className={clsx(styles['card__limit'])}>
          <p className={clsx(styles['card__limit__ttl'])}>
            {t('Participation')} <StartDateTime reservation={reservation} />{' '}
          </p>
          <div className={clsx(styles['card__limit__txt'])}>
            <p>
              <Timer reservation={reservation} product={product} />
            </p>
          </div>
        </div>
      ) : (
        <StartedLimited reservation={reservation} product={product} />
      )}
    </>
  );
};

const NotStartedNormal = ({ reservation }: Props) => {
  const { t } = useTranslation();
  return (
    <div className={clsx(styles['card__limit'])}>
      <p className={clsx(styles['card__limit__ttl'])}>{t('Participation')} </p>
      <div className={clsx(styles['card__limit__txt'])}>
        <p>
          <span>
            <StartDateTime reservation={reservation} />
          </span>
        </p>
      </div>
    </div>
  );
};

const StartedFreePass = ({ reservation, product }: Props) => {
  const isEtarnal =
    product?.qr_checkin_settings?.expiration_date?.relative_date?.type === 'ETERNAL';

  return (
    <>
      {isEtarnal ? (
        <FreePassEternal reservation={reservation} product={product} />
      ) : (
        <StartedLimited reservation={reservation} product={product} />
      )}
    </>
  );
};

const FreePassEternal = ({ reservation }: Props) => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <div className={clsx(styles['card__limit'])}>
        <p className={clsx(styles['card__limit__ttl'])}>{t('Expiry date')}</p>
        <div className={clsx(styles['card__limit__txt'])}>
          <p>
            {i18n.language === 'ja-JP' ? (
              <>
                <span>
                  {moment
                    .tz(reservation?.start_date_time_utc, reservation?.start_timezone)
                    .format('YYYY年MM月DD日')}
                </span>
                まで有効
              </>
            ) : (
              <>
                Valid until{' '}
                <span>
                  {moment
                    .tz(reservation?.start_date_time_utc, reservation?.start_timezone)
                    .format('YYYY/MM/DD')}
                </span>
              </>
            )}
          </p>
        </div>
      </div>
    </>
  );
};

const NotStartedFreePass = ({ reservation, product }: Props) => {
  return <FreePassEternal reservation={reservation} product={product} />;
};

const StartedLimited = ({ reservation, product }: Props) => {
  const { t, i18n } = useTranslation();
  const expiredAt =
    reservation?.checkin_info?.fixed_expiration_date_time_utc ||
    reservation?.checkin_info?.expiration_date_time_utc;

  return (
    <div className={clsx(styles['card__limit'])}>
      <p className={clsx(styles['card__limit__ttl'])}>{t('Expiry date')}</p>
      <div className={clsx(styles['card__limit__txt'])}>
        <span>
          {moment.tz(expiredAt, reservation?.start_timezone).locale(i18n.language).format('ll')}
        </span>
        {t('Valid until {{date}}', {
          date: moment.tz(expiredAt, reservation?.start_timezone).format('HH:mm'),
        })}
        <span className={clsx(styles['card__limit__txt__timer'])}>
          <Timer reservation={reservation} product={product} />
        </span>
      </div>
    </div>
  );
};

const StartedAnnualPass = ({ reservation, product }: Props) => {
  const { t, i18n } = useTranslation();

  return (
    <div className={clsx(styles['card__limit'])}>
      <p className={clsx(styles['card__limit__ttl'])}>
        {t('Valid until {{date}}', {
          date: getExpirationLimit(reservation)?.locale(i18n.language)?.format('lll'),
        })}
      </p>
      <div className={clsx(styles['card__limit__txt'])}>
        <p>
          <Timer reservation={reservation} product={product} />
        </p>
      </div>
    </div>
  );
};

export const Timer = ({ reservation, product }: { reservation: Reservation; product: Product }) => {
  if (product.qr_checkin_settings?.expiration_date?.display_type === 'CLOCK') {
    return <Clock />;
  }

  return <CountDownTimer reservation={reservation} />;
};

export const CountDownTimer = ({ reservation }: { reservation: Reservation }) => {
  const { t } = useTranslation();

  const limit = getExpirationLimit(reservation);

  const { days, seconds, minutes, hours, isRunning } = useTimer({
    expiryTimestamp: limit?.toDate() ?? new Date(),
  });

  if (!limit || !isRunning) return null;

  return (
    <p>
      {days > 0 && t('{{days}} days', { days })}{' '}
      {`${fillZero(hours)}:${fillZero(minutes)}:${fillZero(seconds)}`}
    </p>
  );
};

const getExpirationLimit = (reservation: Reservation) => {
  const expiredAt =
    reservation?.checkin_info?.fixed_expiration_date_time_utc ||
    reservation?.checkin_info?.expiration_date_time_utc;

  if (!expiredAt) {
    return null;
  }

  return moment.tz(expiredAt, reservation?.start_timezone ?? 'UTC');
};

export const Clock = () => {
  const { i18n } = useTranslation();
  const { seconds, minutes, hours, ampm } = useTime({ format: '12-hour' });
  return (
    <>
      {i18n.language === 'ja-JP' && <>{`${ampm === 'pm' ? '午後' : '午前'}`}</>}{' '}
      {`${('00' + hours).slice(-2)}:${('00' + minutes).slice(-2)}:${('00' + seconds).slice(-2)}`}{' '}
      {i18n.language !== 'ja-JP' && <>{`${ampm === 'pm' ? 'PM' : 'AM'}`}</>}
    </>
  );
};

export const StartDateTime = ({ reservation }: { reservation: Reservation }) => {
  const { i18n } = useTranslation();
  const startDateTimeLocal = moment.tz(
    reservation.start_date_time_utc,
    reservation.start_timezone || 'UTC'
  );

  return (
    <>
      {`${
        i18n.language === 'ja-JP'
          ? startDateTimeLocal.format('YYYY年MM月DD日')
          : startDateTimeLocal.format('YYYY/MM/DD')
      }`}{' '}
      <br />{' '}
      {`${
        i18n.language === 'ja-JP'
          ? startDateTimeLocal.format('HH:mm')
          : startDateTimeLocal.format('hh:mm') + (startDateTimeLocal.hours() >= 12 ? 'PM' : 'AM')
      } ${reservation.start_time_description ?? ''}`}
    </>
  );
};

const fillZero = (num: number) => {
  return ('00' + num.toString()).slice(-2);
};
