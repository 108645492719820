import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { Reservation } from 'models/reservation';
import { Product } from 'models/product';
import {
  getCheckinStatus,
  isReservationCanceled,
  isReservationNotConfirmed,
} from 'lib/util/getCheckinStatus';

import styles from './ETicketPage.module.css';

export const KeyVisual = ({
  reservation,
  product,
}: {
  reservation: Reservation;
  product: Product;
}) => {
  const { t } = useTranslation();
  let imageURL = '';
  if (product.media) {
    const firstImage = product.media.find((item) => item.type === 'IMAGE');
    if (firstImage) {
      imageURL = firstImage.url;
    }
  }

  if (isReservationCanceled(reservation) || isReservationNotConfirmed(reservation)) {
    let headerWord = '';
    if (isReservationCanceled(reservation)) {
      headerWord = t('Canceled');
    } else {
      headerWord = t('Not Available');
    }
    return (
      <>
        <p className={clsx(styles['card__pic__label'], styles['before'])}>{headerWord}</p>
        <div className={clsx(styles['card__pic__img'], styles['before'])}>
          <img src={imageURL} />
        </div>
      </>
    );
  }

  switch (getCheckinStatus(reservation)) {
    case 'not_started':
      return (
        <>
          <p className={clsx(styles['card__pic__label'], styles['before'])}>
            {t('Not started yet')}
          </p>
          <div className={clsx(styles['card__pic__img'], styles['before'])}>
            <img src={imageURL} />
          </div>
        </>
      );
    case 'started':
      return (
        <>
          <p className={clsx(styles['card__pic__label'], styles['using'])}> {t('In use')} </p>
          <div className={clsx(styles['card__pic__img'], styles['using'])}>
            <img src={imageURL} />
          </div>
        </>
      );
    case 'expired':
      return (
        <>
          <p className={clsx(styles['card__pic__label'], styles['after'])}> {t('Redeemed')} </p>
          <div className={clsx(styles['card__pic__img'], styles['after'])}>
            <img src={imageURL} />
            <div className={clsx(styles['card__pic__img__stamp'])}>
              <i></i>
              {t('Redeemed')}
            </div>
          </div>
        </>
      );
    default:
      return null;
  }
};
