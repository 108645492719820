import moment from 'moment-timezone';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import config from 'config';
import { SalesOffer, SalesOfferConditions } from 'models/salesOffer';
import { getGuestCount } from 'lib/util/getGuestCount';
import { useUrl } from 'hooks/useUrl';

import styles from './SpecialOfferPopup.module.css';
import { Reservation } from 'models/reservation';
import { ProductSummary } from 'models/product';
import { GuidancePageCoupon } from 'models/guidancePage';
import { CouponModal } from 'components/Guidance/CouponModal';
import { ApiKeyContext } from 'contexts/ApiKeyContext';

const findConditionRule = (
  offer: SalesOffer,
  reservation: Reservation
): SalesOfferConditions | null => {
  if (!offer?.condition_rules) {
    return null;
  }

  const currentDate = moment.tz(reservation?.start_timezone ?? 'UTC').format('YYYY-MM-DD');
  const currentTimeOfDay = moment.tz(reservation?.start_timezone ?? 'UTC').format('HH:mm:ss');

  const conditionRule = offer.condition_rules.find((item) => {
    if (
      item.booked_product_ids?.length > 0 &&
      !item.booked_product_ids?.includes(reservation?.product_id ?? '')
    ) {
      return false;
    }
    if (item.participation_start_date && currentDate < item.participation_start_date) {
      return false;
    }
    if (item.participation_end_date && currentDate > item.participation_end_date) {
      return false;
    }
    if (item.time_of_day_start && currentTimeOfDay < item.time_of_day_start) {
      return false;
    }
    if (item.time_of_day_end && currentTimeOfDay > item.time_of_day_end) {
      return false;
    }
    return true;
  });

  return conditionRule ?? null;
};

interface Props {
  offer: SalesOffer;
  reservation: Reservation;
}

export const SpecialOfferContentPane = React.forwardRef<HTMLDivElement, Props>(
  ({ offer, reservation }, ref) => {
    const { t } = useTranslation();

    const [timeRemaining, setTimeRemaining] = React.useState('');
    const [percentRemaining, setPercentRemaining] = React.useState(0);
    const [activeCoupon, setActiveCoupon] = React.useState<GuidancePageCoupon | null>(null);
    const conditionRule = findConditionRule(offer, reservation);
    const { useApiKeyInPaths, apiKey } = React.useContext(ApiKeyContext);
    const [changeReservationUrl, setChangeReservationUrl] = React.useState('');

    const limitStartTime = conditionRule?.expiration_time_of_day
      ? conditionRule?.time_of_day_start || '00:00'
      : '';
    const limitEndTime = conditionRule?.expiration_time_of_day;

    React.useEffect(() => {
      const intervalId = setInterval(() => {
        if (reservation.start_timezone && limitStartTime && limitEndTime) {
          const now = moment.tz(reservation.start_timezone);
          const startTime = moment(limitStartTime, 'HH:mm');
          const endTime = moment(limitEndTime, 'HH:mm');
          const duration = moment.duration(endTime.diff(startTime));
          const percent = (now.diff(startTime) / duration.asMilliseconds()) * 100;
          const timeRemaining = moment
            .utc(duration.asMilliseconds() - now.diff(startTime))
            .format('HH:mm:ss');
          setTimeRemaining(timeRemaining);
          setPercentRemaining(percent);
        }
      }, 1000);

      return () => clearInterval(intervalId);
    }, [limitEndTime, limitStartTime, reservation.start_timezone]);

    const getPageUrl = (pageName: string) => {
      return `${useApiKeyInPaths ? `/${apiKey}` : ''}${pageName}`;
    };

    let contentName = '';
    let contentImageUrl = '';
    let product: ProductSummary | null = null;
    if (['ANNUAL_PASS', 'PRODUCT'].includes(offer?.content?.content_type)) {
      // product case
      product = offer.content_products?.length > 0 ? offer.content_products[0] : null;

      contentName = product?.product_name ?? '';
      contentImageUrl = product?.media?.find((item) => item.type === 'IMAGE')?.url ?? '';
    } else {
      const coupon = offer.content_guidance_coupon;

      contentName = coupon?.title ?? '';
      contentImageUrl = offer.content?.thumbnail_url ?? '';
    }

    const changeReservationBaseUrl = useUrl(`/products/${product?.id ?? ''}/change`);

    React.useEffect(() => {
      if (!product) {
        return;
      }
      if (offer?.content?.content_type !== 'ANNUAL_PASS') {
        return;
      }
      const url = `${config.apiUrl}/products/${product.id}/instances/`;
      const startDate = moment.tz(reservation.start_date_time_utc, reservation.start_timezone);
      const endDate = startDate.clone().add(1, 'days');
      axios
        .get(url, {
          params: {
            start_date_local_from: startDate.format('YYYY-MM-DD'),
            start_date_local_to: endDate.format('YYYY-MM-DD'),
          },
          headers: { 'x-api-key': apiKey },
        })
        .then((response) => {
          if (response?.data?.instances?.length > 0) {
            const targetInstance = response.data.instances.reverse()[0];
            const guestCount = getGuestCount(reservation);

            const searchParams = new URLSearchParams();
            searchParams.append('r', reservation.id);
            searchParams.append('productInstanceId', targetInstance.id);
            searchParams.append('ts', reservation.last_updated_date_time_utc);
            searchParams.append('guestCount', JSON.stringify(guestCount));

            let url = '';
            if (changeReservationBaseUrl.includes('?')) {
              url = `${changeReservationBaseUrl}&${searchParams.toString()}`;
            } else {
              url = `${changeReservationBaseUrl}?${searchParams.toString()}`;
            }
            setChangeReservationUrl(url);
          }
        });
    }, [product, offer]);

    return (
      <div className={styles['recommend__content']} ref={ref}>
        {contentImageUrl && (
          <div className={styles['recommend__content__pic']}>
            <img src={contentImageUrl} alt={contentName} />
          </div>
        )}

        {timeRemaining && (
          <>
            <div className={styles['card__limit']}>
              <p className={styles['card__limit__ttl']}>{t('Time Remaining')}</p>
              <div className={styles['card__limit__txt']}>
                <p>{timeRemaining}</p>
              </div>
            </div>
            <div className={styles['recommend__content__limitBar']}>
              <div style={{ width: `${percentRemaining}%` }}>
                <i></i>
              </div>
            </div>
          </>
        )}

        <p className={styles['recommend__content__txt']}>
          {t('{{offerContentName}} is available today. Take advantage of this special offer!', {
            offerContentName: contentName,
          })}
        </p>
        {offer?.content?.content_type === 'PRODUCT' && (
          <a
            target="_blank"
            rel="noreferrer"
            className={styles['recommend__content__btn']}
            href={getPageUrl(`/products/${product?.id}?r=${reservation.id}&offer_id=${offer.id}`)}
            onClick={() => {
              axios.post(
                `${config.apiUrl}/salesofferevents`,
                {
                  sales_offer_id: offer.id,
                  reservation_id: reservation.id,
                  event_type: 'CLICK',
                },
                {
                  headers: { 'x-api-key': apiKey },
                }
              );
              return true;
            }}
          >
            {t('MORE')}
          </a>
        )}
        {offer?.content?.content_type === 'ANNUAL_PASS' && (
          <a
            target="_blank"
            rel="noreferrer"
            className={styles['recommend__content__btn']}
            href={changeReservationUrl}
            onClick={() => {
              axios.post(
                `${config.apiUrl}/salesofferevents`,
                {
                  sales_offer_id: offer.id,
                  reservation_id: reservation.id,
                  event_type: 'CLICK',
                },
                {
                  headers: { 'x-api-key': apiKey },
                }
              );
              return true;
            }}
          >
            {t('MORE')}
          </a>
        )}
        {offer?.content?.content_type === 'COUPON' && offer?.content_guidance_coupon && (
          <a
            target="_blank"
            rel="noreferrer"
            className={styles['recommend__content__btn']}
            onClick={() => {
              setActiveCoupon(offer?.content_guidance_coupon as any);
              axios.post(
                `${config.apiUrl}/salesofferevents`,
                {
                  sales_offer_id: offer.id,
                  reservation_id: reservation.id,
                  event_type: 'CLICK',
                },
                {
                  headers: { 'x-api-key': apiKey },
                }
              );
            }}
          >
            {t('See Coupon')}
          </a>
        )}
        {activeCoupon && (
          <CouponModal
            coupon={activeCoupon}
            open={true}
            onClose={() => {
              setActiveCoupon(null);
            }}
            pageId={''}
            salesOfferId={offer.id}
            insertRoot
          />
        )}
      </div>
    );
  }
);
