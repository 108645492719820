import moment from 'moment-timezone';
import { Reservation } from 'models/reservation';
import { Product } from 'models/product';
import { GetSettingsResponse } from 'models/settings';

export const getConsumedGuestCounts = (
  stubKey: string,
  reservation: Reservation
): { [key: string]: number } => {
  const consumedGuestCount = (reservation.checkin_info?.checkin_records ?? [])
    .filter((record) => !stubKey || record.stub_key === stubKey)
    .reduce((acc, record) => {
      for (const guestTypeCount of record.guest_type_counts ?? []) {
        if (!guestTypeCount.guest_type_key) {
          continue;
        }
        acc[guestTypeCount.guest_type_key] =
          (acc[guestTypeCount.guest_type_key] ?? 0) + (guestTypeCount.count ?? 0);
      }
      return acc;
    }, {} as { [key: string]: number });

  return consumedGuestCount;
};

export const getMaxGuestCounts = (reservation: Reservation): { [key: string]: number } => {
  const maxGuestCount = reservation.guests.reduce((acc, guest) => {
    if (!guest.guest_type_key) {
      return acc;
    }
    acc[guest.guest_type_key] = (acc[guest.guest_type_key] ?? 0) + 1;
    return acc;
  }, {} as { [key: string]: number });

  return maxGuestCount;
};

export const getRemainingGuestCounts = (
  stubKey: string,
  reservation: Reservation
): { [key: string]: number } => {
  const maxGuestCount = getMaxGuestCounts(reservation);
  const consumedGuestCount = getConsumedGuestCounts(stubKey, reservation);

  for (const guestTypeKey of Object.keys(maxGuestCount)) {
    maxGuestCount[guestTypeKey] -= consumedGuestCount[guestTypeKey] ?? 0;
  }

  return maxGuestCount;
};

export const countTotalRemainingGuestCount = (remainingGuestCounts: {
  [key: string]: number;
}): number => {
  let total = 0;
  for (const guestTypeKey of Object.keys(remainingGuestCounts)) {
    total += remainingGuestCounts[guestTypeKey];
  }
  return total;
};

export const getStubTotalConsumedGuestCounts = (
  stubKey: string,
  reservation: Reservation
): number => {
  const consumedGuestCount = getConsumedGuestCounts(stubKey, reservation);
  let total = 0;
  for (const guestTypeKey of Object.keys(consumedGuestCount)) {
    total += consumedGuestCount[guestTypeKey];
  }
  return total;
};

export const isSplitCheckedInStub = (reservation: Reservation, stubKey: string): boolean => {
  const max = getMaxGuestCounts(reservation);
  const consumed = getConsumedGuestCounts(stubKey, reservation);

  for (const guestTypeKey of Object.keys(max)) {
    if (max[guestTypeKey] !== consumed[guestTypeKey]) {
      return false;
    }
  }
  return true;
};

export const getCheckinStubDisplayText = (
  reservation: Reservation,
  product: Product,
  stub: { key: string; text: string; options?: { key: string; text: string }[] }
): string => {
  if (product?.qr_checkin_settings?.should_count_guests_for_checkin_with_guest_type) {
    return stub?.text || '';
  }
  const record = (reservation?.checkin_info?.checkin_records || []).find(
    (record) => record.stub_key === stub.key
  );

  const stubOption = (stub?.options ?? []).find((option) => option.key === record?.stub_option_key);

  return stubOption?.text || stub?.text || '';
};

export const getCheckinTimeText = (
  reservation: Reservation,
  stubKey: string,
  locale: string
): string => {
  if ((reservation?.checkin_info?.checkin_records || []).length === 0) {
    return '';
  }

  const record = (reservation?.checkin_info?.checkin_records || []).find((record) => {
    if (!stubKey) {
      return true;
    }
    return record.stub_key == stubKey;
  });

  if (!record) {
    return '';
  }

  return moment
    .tz(record.date_time_utc, reservation.start_timezone || 'UTC')
    .locale(locale)
    .format('lll');
};

export const isCheckinedStub = (
  reservation: Reservation,
  product: Product,
  stubKey: string
): boolean => {
  if (product?.qr_checkin_settings?.should_count_guests_for_checkin_with_guest_type) {
    return isSplitCheckedInStub(reservation, stubKey);
  }
  if (
    (reservation?.checkin_info?.checkin_records || []).find((record) => record.stub_key === stubKey)
  ) {
    return true;
  }
  return false;
};

export const isCancellableSetting = (
  product: Product,
  settings: GetSettingsResponse,
  reservation?: Reservation
) => {
  if (
    !settings?.subscriptions?.some(
      (subscription) =>
        subscription.key == 'feature-guest-my-page' && subscription.status === 'ACTIVE'
    )
  ) {
    return false;
  }

  if (reservation?.checkin_info?.checkin_status !== 'NOT_CHECKED_IN') {
    return false;
  }

  if (product && product.guest_my_page_settings?.should_use_custom_settings) {
    return product.guest_my_page_settings?.allow_cancelling_reservation ?? false;
  }

  return settings?.guest_my_page_settings?.allow_cancelling_reservation ?? false;
};
