import React from 'react';
import clsx from 'clsx';

import styles from './FieldResponseFootprintedModal.module.css';

interface Props {
  prompt: string;
  response: string;
}

export const FormatText = ({ prompt, response }: Props) => {
  return (
    <li className={clsx(styles['slideUp__content__list__item'])}>
      <p className={clsx(styles['slideUp__content__list__item__ttl'])}>{prompt}</p>
      <p className={clsx(styles['slideUp__content__list__item__body'])}>{response}</p>
    </li>
  );
};
