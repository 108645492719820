import * as React from 'react';
import JsBarcode from 'jsbarcode';

interface Props {
  value: string;
}

export const BarCode = ({ value }: Props) => {
  const ref = React.useRef<HTMLImageElement>(null);

  React.useEffect(() => {
    if (ref.current) {
      try {
        JsBarcode(ref.current, value, {
          format: 'EAN13',
          displayValue: true,
          height: 50,
          margin: 4,
        });
      } catch (e) {
        console.error(e);
      }
    }
  }, [ref, value]);
  return (
    <div style={{ margin: '0px 50px', backgroundColor: '#FFF' }}>
      <img ref={ref} alt="barcode" style={{ height: '70px', width: '260px' }} />;
    </div>
  );
};
