import * as React from 'react';

import { Reservation } from 'models/reservation';

export interface GuestTypeCount {
  guestTypeKey: string;
  guestTypeTitle?: string;
  count: number;
}

export interface RedeemTarget {
  reservation: Reservation;
  stubKey?: string;
  stubOptionKey?: string;
  guestTypeCounts?: GuestTypeCount[];
  redemptionCount?: number;
  presetRedemptionCountKey?: string;
}

interface ETicketRedeemTargetContextInterface {
  redeemTarget: RedeemTarget | null;
  setRedeemTarget: (payload: RedeemTarget | null) => void;
}

export const ETicketRedeemTargetContext = React.createContext<ETicketRedeemTargetContextInterface>({
  redeemTarget: null,
  setRedeemTarget: () => undefined,
});
