import * as React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { GuestTypeCount } from 'contexts/ETicketRedeemTargetContext';
import { Reservation } from 'models/reservation';
import { getRemainingGuestCounts, getStubTotalConsumedGuestCounts } from 'lib/util/eTicket';

import styles from './ETicketGuestCountSelector.module.css';

interface Props {
  reservation: Reservation;
  value: GuestTypeCount[];
  onChange: (value: GuestTypeCount[]) => void;
  isBg?: boolean;
  stubKey?: string;
}

export const ETicketGuestCountSelector = ({
  reservation,
  value,
  onChange,
  isBg,
  stubKey,
}: Props) => {
  const { t } = useTranslation();

  const remainingGuestCounts = getRemainingGuestCounts(stubKey ?? '', reservation);

  const totalConsumedGuestCounts = getStubTotalConsumedGuestCounts(stubKey ?? '', reservation);

  const totalRemainingGuestCount = React.useMemo(() => {
    let total = 0;
    for (const guestTypeKey of Object.keys(remainingGuestCounts)) {
      total += remainingGuestCounts[guestTypeKey];
    }
    return total;
  }, [remainingGuestCounts]);

  const guestTypeCounts: {
    guestTypeKey: string;
    guestTypeTitle: string;
    count: number;
  }[] = React.useMemo(() => {
    const guestTypeCounts: { guestTypeKey: string; guestTypeTitle: string; count: number }[] = [];
    for (const guest of reservation.guests) {
      const guestTypeCount = guestTypeCounts.find(
        (guestTypeCount) => guestTypeCount.guestTypeKey === guest.guest_type_key
      );
      if (guestTypeCount) {
        continue;
      }

      guestTypeCounts.push({
        guestTypeKey: guest.guest_type_key,
        guestTypeTitle: guest.guest_type_title || guest.guest_type_key,
        count: 0,
      });
    }

    for (const guestTypeCount of guestTypeCounts) {
      guestTypeCount.count = remainingGuestCounts[guestTypeCount.guestTypeKey];
    }

    return guestTypeCounts;
  }, [reservation.guests]);

  React.useEffect(() => {
    onChange(guestTypeCounts);
  }, [reservation]);

  const redeemAllTicketsHander = () => {
    onChange(guestTypeCounts);
  };

  const changeHandler = (newCount: number, guestTypeCount: GuestTypeCount) => {
    const newGuestTypeCounts = value.map((v) => {
      if (v.guestTypeKey !== guestTypeCount.guestTypeKey) {
        return v;
      }
      return {
        ...v,
        count: newCount,
      };
    });
    onChange(newGuestTypeCounts);
  };

  const selectedGuestTypeCount = value.reduce((acc, v) => acc + v.count, 0);

  if (totalRemainingGuestCount === 0) {
    return null;
  }

  return (
    <div className={clsx(styles['card__action__item__number'], isBg ? styles['bg'] : null)}>
      <label className={clsx(styles['card__action__item__number__all'])}>
        <input
          type="checkbox"
          name=""
          checked={totalRemainingGuestCount === selectedGuestTypeCount}
          onClick={() => {
            if (totalRemainingGuestCount === 0) {
              return;
            }
            redeemAllTicketsHander();
          }}
          readOnly
        />
        <i></i>
        <p>{t('Redeem all tickets')}</p>
      </label>
      <ul className={clsx(styles['card__action__item__number__list'])}>
        {guestTypeCounts.map((guestTypeCount) => (
          <li key={guestTypeCount.guestTypeKey}>
            <p>{guestTypeCount.guestTypeTitle}</p>
            <label className={clsx(styles['form-number'])}>
              <input
                type="number"
                step="1"
                min="1"
                max="2"
                value={
                  value?.find((v) => v.guestTypeKey === guestTypeCount.guestTypeKey)?.count ?? 0
                }
                readOnly
              />
              <span
                className={clsx(styles['down'])}
                onClick={() => {
                  const count =
                    value?.find((v) => v.guestTypeKey === guestTypeCount.guestTypeKey)?.count ?? 0;
                  if (count > 0) {
                    changeHandler(count - 1, guestTypeCount);
                  }
                }}
              >
                -
              </span>
              <span
                className={clsx(styles['up'])}
                onClick={() => {
                  const count =
                    value?.find((v) => v.guestTypeKey === guestTypeCount.guestTypeKey)?.count ?? 0;
                  if (count < remainingGuestCounts[guestTypeCount.guestTypeKey]) {
                    changeHandler(count + 1, guestTypeCount);
                  }
                }}
              >
                +
              </span>
            </label>
            <span>
              / {remainingGuestCounts[guestTypeCount.guestTypeKey]} {t('people')}
            </span>
          </li>
        ))}
      </ul>
      <div className={clsx(styles['card__action__item__number__summary'])}>
        <p>{t('Total')}</p>
        <p>
          <span>{value?.reduce((acc, v) => acc + v.count, 0) ?? 0}</span>
          {t('people')}
        </p>
      </div>
      <div className={clsx(styles['card__action__item__number__remain'])}>
        <p className={clsx(styles['card__action__item__number__remain__used'])}>
          {t('Tickets redeemed: {{totalConsumedGuestCounts}}', { totalConsumedGuestCounts })}
        </p>
        <p>{t('{{n}} left', { n: totalRemainingGuestCount })}</p>
      </div>
    </div>
  );
};
