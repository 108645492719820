import * as React from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { Box } from '@mui/material';

import config from 'config';
import { SalesOffer } from 'models/salesOffer';

import styles from './SpecialOfferPopup.module.css';
import { SpecialOfferContentPane } from './SpecialOfferContentPane';
import { Reservation } from 'models/reservation';
import { ApiKeyContext } from 'contexts/ApiKeyContext';

interface Props {
  offer: SalesOffer;
  reservation: Reservation;
  bottomPosition?: number;
}

export const SpecialOfferPopup = ({ offer, reservation, bottomPosition }: Props) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = React.useState(false);
  const { apiKey } = React.useContext(ApiKeyContext);

  React.useEffect(() => {
    axios.post(
      `${config.apiUrl}/salesofferevents`,
      {
        sales_offer_id: offer.id,
        reservation_id: reservation.id,
        event_type: 'IMPRESSION',
      },
      {
        headers: { 'x-api-key': apiKey },
      }
    );
  }, [offer.id, reservation.id, apiKey]);

  const headerRef = React.useRef<HTMLDivElement>(null);
  const contentRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const handleOutsideClick = ({ target }: Event) => {
      if (target instanceof Node && !contentRef?.current?.contains(target)) {
        setExpanded(false);
      }
    };

    window.document.addEventListener('mousedown', handleOutsideClick, {
      capture: true,
    });
    window.document.addEventListener('touchstart', handleOutsideClick, {
      capture: true,
    });
    return () => {
      window.document.removeEventListener('mousedown', handleOutsideClick, {
        capture: true,
      });
      window.document.removeEventListener('touchstart', handleOutsideClick, {
        capture: true,
      });
    };
  }, []);

  const panelStyle = { transform: expanded ? 'translateY(0)' : 'translateY(100%)' };

  return (
    <>
      {!expanded && (
        <Box
          className={styles['recommend__btnWrapper']}
          sx={{
            bottom: bottomPosition || 0,
          }}
        >
          <Box display="flex" width="100%" justifyContent="center">
            <button
              className={styles['recommend__btn']}
              onClick={() => {
                setExpanded(true);
              }}
            >
              <div>
                <img src="/static/images/ic_megaphone.svg" alt="megaphone" />
                {t('Special Offer!')}
              </div>
            </button>
          </Box>
        </Box>
      )}
      <div style={panelStyle} className={styles['recommend']} id="recommend">
        <div className={styles['recommend__header']} ref={headerRef}>
          <div className={styles['recommend__header__ic']} onClick={() => setExpanded(!expanded)}>
            <img src="/static/images/ic_megaphone.svg" alt="megaphone" />
          </div>
          <div className={styles['recommend__header__ttl']}>
            <div className={styles['recommend__header__ttl__badge']}>{t('SPECIAL')}</div>
            <p className={styles['recommend__header__ttl__txt']}>{t('Exclusive offer')}</p>
          </div>
        </div>

        <SpecialOfferContentPane offer={offer} reservation={reservation} ref={contentRef} />
      </div>
    </>
  );
};
