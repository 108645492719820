import * as React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { Reservation } from 'models/reservation';
import { useUrl } from 'hooks/useUrl';

import styles from './ETicketShareUrlButtons.module.css';

export const ETicketShareUrlButtons = ({
  reservation,
  host,
}: {
  reservation: Reservation;
  host: string;
}) => {
  const { t } = useTranslation();
  const eTicketPath = useUrl(`/eticket?r=${reservation.id}`);

  const eTicketUrl = `${host}${eTicketPath}`;

  const [copySuccess, setCopySuccess] = React.useState(false);

  return (
    <ul className={clsx(styles['card__member'])}>
      <li className={clsx(styles['card__member__item'], styles['flex'])}>
        <a
          className={clsx(styles['btn'], styles['btn--share'], styles['line'])}
          href={`https://line.me/R/msg/text/?${encodeURIComponent(eTicketUrl)}`}
        >
          {t('Share on LINE')}
        </a>
        <a
          className={clsx(styles['btn'], styles['btn--share'], styles['url'])}
          onClick={() => {
            navigator.clipboard.writeText(eTicketUrl);
            setCopySuccess(true);
          }}
        >
          {copySuccess ? t('Copied') : t('Copy URL')}
        </a>
      </li>
    </ul>
  );
};
