import moment from 'moment-timezone';
import { WithTranslation } from 'react-i18next';

import { Reservation } from 'models/reservation';

const isToday = (dateTimeUtc: string, timezone: string): boolean => {
  const today = moment().tz(timezone).format('YYYY-MM-DD');
  return moment.tz(dateTimeUtc, timezone).format('YYYY-MM-DD') === today;
};

export const todayIsParticipationDate = (reservation: Reservation): boolean => {
  return [
    reservation?.start_date_time_utc,
    ...(reservation.package_component_reservation_summaries ?? []).map(
      (reservationSummary) => reservationSummary.start_date_time_utc
    ),
  ].some((dateTime) => isToday(dateTime ?? '', reservation.start_timezone ?? ''));
};

export const todayIsInParticipationRuleRange = (reservation: Reservation): boolean => {
  const fromDate = moment
    .tz(reservation.free_start_date_time_from, reservation.start_timezone)
    .hour(0)
    .minute(0);
  const toDate = moment
    .tz(reservation.free_start_date_time_to, reservation.start_timezone)
    .hour(23)
    .minute(59);
  const today = moment().tz(reservation.start_timezone);
  if (fromDate.isSameOrBefore(today) && toDate.isSameOrAfter(today)) {
    return true;
  }
  return false;
};

export const getCheckinErrorReason = (
  reservation: Reservation | null,
  t: WithTranslation['t']
): string => {
  if (reservation?.status !== 'CONFIRMED') {
    return t('Checkin not available for the scanned QR code.');
  }

  const isExpired = reservation?.checkin_info?.expiration_date_time_utc
    ? moment().isAfter(
        moment.tz(
          reservation?.checkin_info?.expiration_date_time_utc,
          reservation?.start_timezone ?? 'UTC'
        )
      )
    : false;

  if (isExpired) {
    return t('The QR code is Expired.');
  }

  const checkinStatus = reservation?.checkin_info?.checkin_status;

  if (checkinStatus === 'NOT_CHECKED_IN') {
    if (reservation?.free_start_date_time_from && reservation?.free_start_date_time_to) {
      if (!todayIsInParticipationRuleRange(reservation)) {
        return t('Outside of participation period and checkin is not available.');
      }
    } else {
      if (!todayIsParticipationDate(reservation)) {
        return t('You must checkin on the day of participation.');
      }
    }
  }

  return '';
};

export const getCheckinErrorReasonForETicket = (
  reservation: Reservation | null,
  t: WithTranslation['t']
): string => {
  if (reservation?.status !== 'CONFIRMED') {
    return t('Ticket is not available.');
  } else {
    const checkinStatus = reservation?.checkin_info?.checkin_status;

    const isExpired = reservation?.checkin_info?.expiration_date_time_utc
      ? moment().isAfter(
          moment.tz(
            reservation?.checkin_info?.expiration_date_time_utc,
            reservation?.start_timezone ?? 'UTC'
          )
        )
      : false;

    if (checkinStatus !== 'NOT_CHECKED_IN' && !isExpired) {
      return '';
    }

    if (reservation?.free_start_date_time_from && reservation?.free_start_date_time_to) {
      if (!todayIsInParticipationRuleRange(reservation)) {
        return t('Ticket is available during the participation period.');
      }
    } else {
      if (reservation && !todayIsParticipationDate(reservation)) {
        return t('Ticket is available only on the day of participation.');
      }
    }
  }
  return '';
};
