import { useJsApiLoader } from '@react-google-maps/api';

const libraries: any = ['geometry', 'places'];
export const useGoogleMapsApi = () => {
  return useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyCMYN4k9evL-EDlJCOp6zE4LCwphOwYUvo',
    libraries: libraries,
  });
};
