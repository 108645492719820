import * as React from 'react';
import { useTranslation, WithTranslation } from 'react-i18next';
import { Reservation } from 'models/reservation';
import { Product } from 'models/product';
import { getGuestNameText } from 'components/Checkin/utils';
import clsx from 'clsx';

import { FormatFileUpload } from 'components/FieldResponseFootprintedModal/FormatFileUpload';
import { FormatText } from 'components/FieldResponseFootprintedModal/FormatText';

import { escapeFieldName } from 'lib/util/escapeFieldName';
import styles from './ETicketFieldResponsePane.module.css';

interface Props {
  reservation: Reservation;
  product: Product;
  setHeight?: (height: number) => void;
}

export const ETicketFieldResponsePane = ({ reservation, product, setHeight }: Props) => {
  const { t } = useTranslation();
  const sliderRef = React.useRef<HTMLDivElement>(null);
  const ulref = React.useRef<HTMLUListElement>(null);
  const [onMounted, setOnMounted] = React.useState<boolean>(false);

  const showScrollButtons = reservation?.guests.length > 1;

  const scroll = (direction: string) => {
    if (!sliderRef.current) {
      return;
    }

    const slideWidth = sliderRef.current.offsetWidth;
    const currentScroll = sliderRef.current.scrollLeft;

    if (direction === 'prev') {
      sliderRef.current.scrollTo({
        left: currentScroll - slideWidth,
        behavior: 'smooth',
      });
    } else {
      sliderRef.current.scrollTo({
        left: currentScroll + slideWidth,
        behavior: 'smooth',
      });
    }
  };

  const getFieldResponsePromptAndFormatFromKey = (key: string, product: Product) => {
    if (key === 'representative_name') {
      return {
        prompt: t('Name'),
        format: 'text',
      };
    }
    if (key === 'supplier_reference') {
      return {
        prompt: t('Booking reference'),
        format: 'text',
      };
    }
    const field = product.reservation_form_fields?.find((field) => field.key === key);
    if (field) {
      return {
        prompt: field.prompt,
        format: field.format,
      };
    }
    return null;
  };

  const displayItems = (product?.qr_checkin_settings?.footprinted_modal_display_items ?? []).map(
    (item) => {
      return {
        key: escapeFieldName(item.key ?? ''),
        title: item.title,
      };
    }
  );

  const imageOnLoadedHandler = () => {
    if (ulref.current) {
      setHeight?.(ulref.current.clientHeight);
    }
  };

  const getResponseFromKey = (
    key: string,
    reservation: Reservation,
    guestIndex: number,
    t: WithTranslation['t']
  ): any => {
    if (key === 'representative_name') {
      return getGuestNameText(reservation, t);
    }
    if (key === 'supplier_reference') {
      return reservation.supplier_reference;
    }

    const response = reservation.field_responses?.find((response) => response.key === key);
    if (response) {
      return response?.response;
    }
    // return the response first find guest
    return reservation.guests[guestIndex]?.field_responses?.find((response) => response.key === key)
      ?.response;
  };

  React.useEffect(() => {
    setOnMounted(true);
  }, []);

  if (!onMounted) {
    return null;
  }

  return (
    <div className={styles['sideSlider']}>
      {showScrollButtons && (
        <button className={styles['prev']} onClick={() => scroll('prev')}>
          &lt;
        </button>
      )}
      <div className={clsx(styles['sideSlides'])} ref={sliderRef}>
        {reservation.guests.map((_, index) => (
          <div className={styles['sideSlide']} key={index}>
            <ul className={clsx(styles['slideUp__content__list'])} ref={ulref}>
              {displayItems.map((item, idx) => {
                const promptAndFormat = getFieldResponsePromptAndFormatFromKey(
                  item.key ?? '',
                  product
                );
                if (!promptAndFormat) {
                  return null;
                }
                const response = getResponseFromKey(item.key ?? '', reservation, index, t);
                if (!response) {
                  return null;
                }
                if (
                  promptAndFormat.format === 'file-upload' ||
                  promptAndFormat.format === 'image-upload'
                ) {
                  return (
                    <FormatFileUpload
                      key={idx}
                      prompt={item.title ?? ''}
                      response={response}
                      onLoaded={imageOnLoadedHandler}
                    />
                  );
                }
                return <FormatText key={idx} prompt={item.title ?? ''} response={response} />;
              })}
            </ul>
          </div>
        ))}
      </div>
      {showScrollButtons && (
        <button className={styles['next']} onClick={() => scroll('next')}>
          &gt;
        </button>
      )}
    </div>
  );
};
