import * as React from 'react';
import clsx from 'clsx';

import styles from './ShowRedeemedETicketCardModal.module.css';

interface Props {
  onClose: () => void;
  children: React.ReactNode;
}

export const ShowRedeemedETicketCardModal = ({ onClose, children }: Props) => {
  return (
    <>
      <div className={clsx(styles['slideUp'], styles['is-active'])}>
        <div className={clsx(styles['slideUp__content'])}>
          <p
            className={clsx(styles['slideUp__content__close'])}
            onClick={() => {
              onClose();
            }}
          ></p>
          {children}
        </div>
        <div className={clsx(styles['slideUp__overlay'])}></div>
      </div>
      <div className={clsx(styles['slideUpBottom'], styles['is-active'])}></div>
    </>
  );
};
