import moment from 'moment-timezone';

import { Reservation } from 'models/reservation';

export const getCheckinStatus = (reservation: Reservation) => {
  if (reservation.checkin_info?.checkin_status === 'NOT_CHECKED_IN') {
    return 'not_started';
  } else if (
    reservation.checkin_info?.checkin_status === 'CHECKED_IN' ||
    reservation.checkin_info?.checkin_status === 'IN_PROGRESS'
  ) {
    if (isExpired(reservation)) {
      return 'expired';
    } else {
      return 'started';
    }
  }
  return 'unsupported';
};

const isExpired = (reservation: Reservation) => {
  if ((reservation?.checkin_info?.checkin_records || []).length === 0) {
    return false;
  }

  const expiredAt =
    reservation?.checkin_info?.fixed_expiration_date_time_utc ||
    reservation?.checkin_info?.expiration_date_time_utc;

  return expiredAt
    ? moment().isAfter(moment.tz(expiredAt, reservation?.start_timezone ?? 'UTC'))
    : false;
};

export const isReservationNotConfirmed = (reservation: Reservation): boolean => {
  switch (reservation.status) {
    case 'WITHDRAWN_BY_AGENT':
    case 'CANCELED_BY_AGENT':
    case 'CANCELED_BY_GUEST':
    case 'DECLINED_BY_SUPPLIER':
    case 'CANCELED_BY_SUPPLIER':
    case 'CANCEL_CONFIRMED_BY_SUPPLIER':
    case 'CANCEL_DECLINED_BY_SUPPLIER':
    case 'REQUESTED':
    case 'STANDBY':
      return true;
    default:
      return false;
  }
};

export const isReservationCanceled = (reservation: Reservation): boolean => {
  switch (reservation.status) {
    case 'WITHDRAWN_BY_AGENT':
    case 'CANCELED_BY_AGENT':
    case 'CANCELED_BY_GUEST':
    case 'DECLINED_BY_SUPPLIER':
    case 'CANCELED_BY_SUPPLIER':
    case 'CANCEL_CONFIRMED_BY_SUPPLIER':
    case 'CANCEL_DECLINED_BY_SUPPLIER':
      return true;
    default:
      return false;
  }
};
