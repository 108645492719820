import * as React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { GuestTypeCount } from 'contexts/ETicketRedeemTargetContext';
import { Product, ProductETicketStub } from 'models/product';
import { Reservation } from 'models/reservation';
import {
  getCheckinStubDisplayText,
  getCheckinTimeText,
  getRemainingGuestCounts,
  isCheckinedStub,
} from 'lib/util/eTicket';
import { ETicketRedeemTargetContext } from 'contexts/ETicketRedeemTargetContext';
import { ETicketGuestCountSelector } from 'components/ETicketGuestCountSelector/ETicketGuestCountSelector';
import { formatTextWithLineBreaks } from 'lib/util/formatTextWithLineBreaks';
import { getStubRedemptionButtonFontSize } from 'lib/util/getStubRedemptionButtonFontSize';
import { ETicketRedemptionCounter } from 'components/ETicketRedemptionCounter/ETicketRedemptionCounter';

import styles from './ETicketRedemptionCard.module.css';

interface Props {
  product: Product;
  reservation: Reservation;
  stub: ProductETicketStub;
}

export const ETicketRedemptionCard = ({ product, reservation, stub }: Props) => {
  const { i18n, t } = useTranslation();

  const { setRedeemTarget } = React.useContext(ETicketRedeemTargetContext);

  const [redeemGuestTypeCounts, setRedeemGuestTypeCounts] = React.useState<GuestTypeCount[]>([]);

  const remainingGuestCounts = getRemainingGuestCounts(stub.key ?? '', reservation);

  const totalRemainingGuestCount = React.useMemo(() => {
    let total = 0;
    for (const guestTypeKey of Object.keys(remainingGuestCounts)) {
      total += remainingGuestCounts[guestTypeKey];
    }
    return total;
  }, [remainingGuestCounts]);

  const [redemptionCount, setRedemptionCount] = React.useState<number>(
    product?.qr_checkin_settings?.should_use_redemption_count ? 1 : 0
  );
  const maxRedemptionCount =
    product?.qr_checkin_settings?.stubs?.find((s) => s.key === stub.key)?.max_redemption_count ?? 0;
  const totalExistingRedemptionCount = (reservation?.checkin_info?.checkin_records ?? []).reduce(
    (acc, record) => {
      if (record.stub_key !== stub.key) return acc;
      return acc + (record.redemption_count ?? 0);
    },
    0
  );

  let isDisabled = false;
  if (product?.qr_checkin_settings?.should_count_guests_for_checkin_with_guest_type) {
    isDisabled = redeemGuestTypeCounts.reduce((acc, v) => acc + v.count, 0) === 0;
  } else if (product?.qr_checkin_settings?.should_use_redemption_count) {
    isDisabled = redemptionCount === 0;
  } else {
    isDisabled = isCheckinedStub(reservation, product, stub.key);
  }
  let isUsed = false;

  if (product?.qr_checkin_settings?.should_count_guests_for_checkin_with_guest_type) {
    isUsed = totalRemainingGuestCount === 0;
  } else if (product?.qr_checkin_settings?.should_use_redemption_count) {
    isUsed = totalExistingRedemptionCount >= maxRedemptionCount;
  } else {
    isUsed = isCheckinedStub(reservation, product, stub.key);
  }

  const redeemHander = async () => {
    setRedeemTarget({
      reservation: reservation,
      stubKey: stub.key ?? '',
      guestTypeCounts: redeemGuestTypeCounts,
      redemptionCount: redemptionCount,
    });
  };

  const buttonText = isUsed
    ? t('Redeemed')
    : product?.qr_checkin_settings?.redemption_button_text || t('Redeem') || '';

  return (
    <li className={clsx(styles['card__action__item'])} key={stub.key}>
      <div className={clsx(styles['card__action__item__ttl'], isUsed ? styles['used'] : null)}>
        <p>{getCheckinStubDisplayText(reservation, product, stub)}</p>
        <span>{getCheckinTimeText(reservation, stub.key, i18n.language)}</span>
      </div>
      <button
        type="button"
        className={clsx(
          styles['btn'],
          isUsed
            ? styles['btn--used']
            : isDisabled
            ? styles['btn--disabled']
            : styles['btn--submit'],
          styles['width-128']
        )}
        disabled={isDisabled}
        onClick={() => {
          redeemHander();
        }}
        style={{
          ...(product?.qr_checkin_settings?.redemption_button_color && !isUsed && !isDisabled
            ? { backgroundColor: product?.qr_checkin_settings?.redemption_button_color }
            : {}),
          ...(product?.qr_checkin_settings?.redemption_button_text_color && !isUsed && !isDisabled
            ? { color: product?.qr_checkin_settings?.redemption_button_text_color }
            : {}),
          fontSize: getStubRedemptionButtonFontSize(buttonText),
        }}
      >
        {formatTextWithLineBreaks(buttonText)}
      </button>

      {product?.qr_checkin_settings?.should_count_guests_for_checkin_with_guest_type && (
        <ETicketGuestCountSelector
          reservation={reservation}
          value={redeemGuestTypeCounts}
          onChange={(newGuestTypeCounts) => {
            setRedeemGuestTypeCounts(newGuestTypeCounts);
          }}
          isBg={true}
          stubKey={stub.key ?? ''}
        />
      )}

      {product?.qr_checkin_settings?.should_use_redemption_count && (
        <ETicketRedemptionCounter
          count={redemptionCount}
          maxCount={maxRedemptionCount}
          onChange={(count: number) => {
            setRedemptionCount(count);
          }}
          reservation={reservation}
          isBg={true}
          stubKey={stub.key ?? ''}
        />
      )}
    </li>
  );
};
