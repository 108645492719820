import * as React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { Reservation } from 'models/reservation';

import styles from './ETicketRedemptionCounter.module.css';

interface Props {
  count: number;
  maxCount: number;
  onChange: (count: number) => void;
  isBg?: boolean;
  reservation: Reservation;
  stubKey?: string;
}

export const ETicketRedemptionCounter = ({
  count,
  maxCount,
  onChange,
  isBg,
  reservation,
  stubKey,
}: Props) => {
  const { t } = useTranslation();

  const totalExistingRedemptionCount = (reservation?.checkin_info?.checkin_records ?? []).reduce(
    (acc, record) => {
      if (stubKey) {
        if (record.stub_key !== stubKey) return acc;
      }
      return acc + (record.redemption_count ?? 0);
    },
    0
  );

  const availableCount = maxCount - totalExistingRedemptionCount;

  React.useEffect(() => {
    onChange(availableCount >= 1 ? 1 : 0);
  }, [availableCount]);

  if (availableCount === 0) {
    return null;
  }

  return (
    <div className={clsx(styles['card__action__item__number'], isBg ? styles['bg'] : null)}>
      <div className={clsx(styles['action'])}>
        <ul className={clsx(styles['card__action__item__number__list'])}>
          <li>
            <p>{t('Enter redemption count')}</p>
            <label className={clsx(styles['form-number'])}>
              <input type="number" step="1" min="1" max="2" value={count} readOnly />
              <span
                className={clsx(styles['down'])}
                onClick={() => {
                  if (count > 0) {
                    onChange(count - 1);
                  }
                }}
              >
                -
              </span>
              <span
                className={clsx(styles['up'])}
                onClick={() => {
                  if (count < availableCount) {
                    onChange(count + 1);
                  }
                }}
              >
                +
              </span>
            </label>
            <span>/ {availableCount}</span>
          </li>
        </ul>
      </div>
      <div className={clsx(styles['card__action__item__number__summary'])}>
        <p>{t('Total')}</p>
        <p>
          <span>{count ?? 0}</span>
        </p>
      </div>
      <div className={clsx(styles['card__action__item__number__remain'])}>
        <p className={clsx(styles['card__action__item__number__remain__used'])}>
          {t('Tickets redeemed: {{totalExistingRedemptionCount}}', {
            totalExistingRedemptionCount,
          })}
        </p>
      </div>
    </div>
  );
};
