import React from 'react';
import clsx from 'clsx';

import styles from './FieldResponseFootprintedModal.module.css';

interface Props {
  prompt?: string;
  response: string;
  onLoaded?: () => void;
}

export const FormatFileUpload = ({ response, onLoaded }: Props) => {
  const parsedResponse = JSON.parse(response);

  if (!parsedResponse) {
    return null;
  }

  if (parsedResponse.length === 0) {
    return null;
  }

  const url = parsedResponse[0].url;

  return (
    <li className={clsx(styles['slideUp__content__list__item'])}>
      <p className={clsx(styles['slideUp__content__list__item__body'], styles['image'])}>
        <img src={url} onLoad={onLoaded} />
      </p>
    </li>
  );
};
