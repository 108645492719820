import * as React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'ducks';

import config from 'config';
import { ApiKeyContext } from 'contexts/ApiKeyContext';
import { Reservation } from 'models/reservation';
import { Product } from 'models/product';
import { todayIsInParticipationRuleRange } from 'lib/util/getCheckinErrorReason';
import { getReservationCheckinStatus } from 'ducks/client/reservation';

export const useETicketRedemptionStatus = (
  reservation: Reservation | undefined,
  product: Product | null
) => {
  const { t, i18n } = useTranslation();
  const { apiKey } = React.useContext(ApiKeyContext);
  const settings = useSelector((state: ReduxState) => state.server.settings.all);
  const dispatch = useDispatch();
  const reservationCheckinStatus = useSelector(
    (state: ReduxState) => state.reservation.reservationCheckinStatus
  );

  const [response, setResponse] = React.useState<{
    readyToCheckin: boolean;
    reason?: string | null;
  }>({
    readyToCheckin: false,
    reason: null,
  });

  const noteForRedemptionModalBeforeCheckinStartTime =
    product?.qr_checkin_settings?.note_for_redemption_modal_before_checkin_start_time ??
    t('Ticket is not available until the redemption start time.');

  const calculateParticipationTime = (): moment.Moment | null => {
    if (!reservation || !product?.qr_checkin_settings?.checkin_start_time_relative) return null;

    const participationTime = moment.tz(
      reservation.start_date_time_utc,
      reservation.start_timezone ?? ''
    );
    const match = product.qr_checkin_settings.checkin_start_time_relative.match(
      /^([+-]?)(\d+):(\d+)$/
    );
    if (!match) throw new Error('Invalid offset format');

    const [, signSymbol, hoursStr, minutesStr] = match;
    const sign = signSymbol === '-' ? -1 : 1;
    const hours = parseInt(hoursStr, 10);
    const minutes = parseInt(minutesStr, 10);

    participationTime.add(sign * hours, 'hours').add(sign * minutes, 'minutes');
    return participationTime;
  };

  React.useEffect(() => {
    if (config.enableReservationCheckinStatus) {
      setResponse({
        reason: reservationCheckinStatus?.reason ?? null,
        readyToCheckin: reservationCheckinStatus?.ready_to_checkin ?? false,
      });
    }
  }, [reservationCheckinStatus]);

  React.useEffect(() => {
    if (!product || !reservation) return;

    if (config.enableReservationCheckinStatus) {
      let checkinApiKey = apiKey;
      if (
        config.enablePartnershipPackageReservation &&
        settings.is_partnership_hub_supplier &&
        reservation.supplier_id !== settings.supplier_id
      ) {
        checkinApiKey = settings.corresponding_organization_booking_widget_api_key ?? apiKey;
      }
      dispatch(getReservationCheckinStatus(checkinApiKey, reservation.id, i18n.language));
    } else {
      const defaultParticipationTime = moment.tz(
        reservation.start_date_time_utc,
        reservation.start_timezone ?? ''
      );

      const defaultParticipationTimeStart = defaultParticipationTime.clone().startOf('day');
      const defaultParticipationTimeEnd = defaultParticipationTime.clone().endOf('day');

      const expiredAt =
        reservation?.checkin_info?.fixed_expiration_date_time_utc ||
        reservation?.checkin_info?.expiration_date_time_utc;

      try {
        if (reservation.status !== 'CONFIRMED') {
          setResponse({
            readyToCheckin: false,
            reason: t('Ticket is not available.'),
          });
          return;
        }

        const checkinStatus = reservation?.checkin_info?.checkin_status;

        if (checkinStatus !== 'NOT_CHECKED_IN') {
          const isExpired = expiredAt
            ? moment().isAfter(moment.tz(expiredAt, reservation?.start_timezone ?? 'UTC'))
            : false;
          if (isExpired) {
            setResponse({
              readyToCheckin: false,
              reason: t('The E-ticket is Expired.'),
            });
            return;
          }
        } else {
          // not checked in
          if (reservation?.free_start_date_time_from && reservation?.free_start_date_time_to) {
            if (!todayIsInParticipationRuleRange(reservation)) {
              setResponse({
                readyToCheckin: false,
                reason: t('Ticket is available during the participation period.'),
              });
              return;
            }
          } else {
            if (product?.qr_checkin_settings?.checkin_start_time_relative) {
              const participationTime = calculateParticipationTime();
              if (moment().isBefore(participationTime)) {
                const checkReadyToCheckin = () => {
                  if (moment().isAfter(participationTime)) {
                    setResponse({ readyToCheckin: true });
                  } else {
                    setResponse({
                      readyToCheckin: false,
                      reason: noteForRedemptionModalBeforeCheckinStartTime,
                    });
                  }
                };

                checkReadyToCheckin();
                const interval = setInterval(checkReadyToCheckin, 60 * 1000);
                return () => clearInterval(interval);
              }
            } else {
              if (moment().isBefore(defaultParticipationTimeStart)) {
                setResponse({
                  readyToCheckin: false,
                  reason: t('Ticket is available only on the day of participation.'),
                });
                return;
              }
            }

            if (reservation?.checkin_info?.checkin_deadline_date_time_utc) {
              const deadline = moment.tz(
                reservation?.checkin_info?.checkin_deadline_date_time_utc,
                reservation.start_timezone ?? ''
              );
              if (moment().isAfter(deadline)) {
                setResponse({
                  readyToCheckin: false,
                  reason: t('Ticket can not be redeemed because the deadline has passed.'),
                });
                return;
              }
            } else {
              if (moment().isAfter(defaultParticipationTimeEnd)) {
                setResponse({
                  readyToCheckin: false,
                  reason: t('Ticket is available only on the day of participation.'),
                });
                return;
              }
            }
          }
        }

        setResponse({ readyToCheckin: true });
      } catch (e) {
        console.error(e);
        setResponse({ readyToCheckin: true });
      }
    }
  }, [product, reservation]);

  return response;
};
