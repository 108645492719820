import * as React from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { CircularProgress, Box } from '@mui/material';

import config from 'config';
import { GetSettingsResponse } from 'models/settings';
import { useUrl } from 'hooks/useUrl';
import { ReduxState } from 'ducks';
import { Product } from 'models/product';
import { GuidancePage } from 'models/guidancePage';
import { Reservation } from 'models/reservation';
import { ApiKeyContext } from 'contexts/ApiKeyContext';
import { CurrentPositionContext } from 'contexts/CurrentPositionContext';
import {
  getCheckinStatus,
  isReservationCanceled,
  isReservationNotConfirmed,
} from 'lib/util/getCheckinStatus';
import { isFreePassReservation } from 'lib/util/isFreePassReservation';
import { leaveFootprint } from 'ducks/client/footprints';
import { ETicketRedeemTargetContext, GuestTypeCount } from 'contexts/ETicketRedeemTargetContext';
import { useSessionLock } from 'hooks/useSessionLock';
import { getCheckinTimeText, isCancellableSetting } from 'lib/util/eTicket';
import { ETicketRedemptionCard } from 'components/ETicketRedemptionCard/ETicketRedemptionCard';
import { ETicketGuestCountSelector } from 'components/ETicketGuestCountSelector/ETicketGuestCountSelector';
import { ETicketShareUrlButtons } from 'components/ETicketShareUrlButtons/ETicketShareUrlButtons';
import { SpecialOfferPopup } from 'components/SpecialOfferPopup/SpecialOfferPopup';
import { formatTextWithLineBreaks } from 'lib/util/formatTextWithLineBreaks';
import { ETicketRedemptionCounter } from 'components/ETicketRedemptionCounter/ETicketRedemptionCounter';
import { logCustomerEventWithNoSideEffects } from 'ducks/client/customerEvents';
import { ETicketViewLogger } from './ETicketViewLogger';
import { CustomerContext } from 'contexts/CustomerContext';
import { PackageETicketContext } from 'contexts/PackageETicketContext';

import { KeyVisual } from './KeyVisual';
import { ExpirationDateTime } from './ExpirationDateTime';
import styles from './ETicketPage.module.css';
import { selectApplicableWebsiteSalesOffers } from 'ducks/client/salesOffers';
import { SpecialOfferTrigger } from 'components/SpecialOfferTrigger/SpecialOfferTrigger';

export const ETicketCard = ({
  reservation,
  product,
  onFootprintClick,
  showFootprintedModal,
  guidancePages,
  initialOpen,
  host,
}: {
  reservation: Reservation;
  product: Product;
  onFootprintClick: () => void;
  showFootprintedModal?: boolean;
  guidancePages?: GuidancePage[] | null;
  initialOpen?: boolean;
  host: string;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { apiKey } = React.useContext(ApiKeyContext);
  const { currentPosition } = React.useContext(CurrentPositionContext);
  const settings = useSelector((state: ReduxState) => state.server.settings.all);
  const footprintLoading = useSelector((state: ReduxState) => state.footprints.loading);
  const { isLocked, error } = useSessionLock({ resourceId: reservation.id });
  const visitorToken = useSelector((state: ReduxState) => state.tracking.visitorToken);
  const { customer } = React.useContext(CustomerContext);

  const [showSpecialOffer, setShowSpecialOffer] = React.useState<boolean>(false);
  const [showDetails, setShowDetails] = React.useState<boolean>(initialOpen ?? true);
  const [showTimer, setShowTimer] = React.useState<boolean>(false);

  const guidanceUrl = useUrl(`/guidance/list?r=${reservation.id}`);

  const notConfirm = isReservationNotConfirmed(reservation);
  const checkinStatus = getCheckinStatus(reservation);
  const webSalesOffers = useSelector(selectApplicableWebsiteSalesOffers);

  React.useEffect(() => {
    setShowTimer(true);
  }, []);
  React.useEffect(() => {
    if (webSalesOffers?.length) {
      const timeoutId = setTimeout(() => {
        setShowSpecialOffer(true);
      }, 1000);

      return () => clearTimeout(timeoutId);
    }
  }, [webSalesOffers]);

  return (
    <>
      <section className={clsx(styles['main__list__item'])}>
        <div className={clsx(styles['card'])}>
          <div className={clsx(styles['card__pic'])}>
            <KeyVisual reservation={reservation} product={product} />
          </div>

          <div className={clsx(styles['card__content'])}>
            <div className={clsx(styles['card__content__pic'])}></div>

            <h2 className={clsx(styles['card__ttl'])}>{reservation.product_name}</h2>

            <>
              {config.enableETicketFraudDetection && (
                <>
                  {!isLocked && (
                    <div className={clsx(styles['card__locked'])}>ほかのブラウザで表示中です</div>
                  )}
                  {error && (
                    <div className={clsx(styles['card__locked'])}>通信エラーが発生しました</div>
                  )}
                </>
              )}
            </>

            <Guest reservation={reservation} />

            <>
              {(checkinStatus === 'started' || checkinStatus === 'expired') && (
                <>
                  {config.enableSeatManagementMultiResource ? (
                    <EquipmentTitleBlockReference reservation={reservation} />
                  ) : (
                    <EquipmentBlockReference reservation={reservation} />
                  )}
                </>
              )}
            </>

            <>
              {checkinStatus !== 'expired' && showTimer && (
                <>
                  <ExpirationDateTime reservation={reservation} product={product} />
                </>
              )}
            </>

            <>
              {checkinStatus === 'expired' && (
                <RedemptionDate reservation={reservation} product={product} />
              )}
            </>

            <>
              {checkinStatus !== 'expired' && showDetails && (
                <>
                  {getCheckinStubs(product).length > 0 ? (
                    <ActionButtonsForMultipleCheckin
                      reservation={reservation}
                      product={product}
                      settings={settings}
                    />
                  ) : (
                    <ActionButtons
                      reservation={reservation}
                      product={product}
                      settings={settings}
                    />
                  )}
                </>
              )}
            </>

            <>{checkinStatus === 'expired' && <BuyAgainButton reservation={reservation} />}</>

            {!notConfirm && (
              <>
                {checkinStatus === 'started' &&
                  (isFreePassReservation(reservation) ||
                    product?.qr_checkin_settings?.should_use_foot_print) && (
                    <div className={clsx(styles['card__show'])}>
                      <button
                        className={clsx(styles['btn'], styles['btn--double'])}
                        disabled={showFootprintedModal || footprintLoading}
                        onClick={async () => {
                          if (!showFootprintedModal) {
                            try {
                              logCustomerEventWithNoSideEffects({
                                apiKey,
                                eventType: 'eticket-footprint',
                                visitorToken,
                                reservationId: reservation.id,
                                metadata: JSON.stringify({
                                  lat: currentPosition?.lat(),
                                  lng: currentPosition?.lng(),
                                  site: 'onsite',
                                }),
                                customerId: customer?.id ?? '',
                              });
                              await dispatch(
                                leaveFootprint(apiKey, {
                                  reservation_id: reservation.id,
                                  latitude: currentPosition?.lat() ?? 0,
                                  longitude: currentPosition?.lng() ?? 0,
                                })
                              );
                            } catch (e) {
                              console.error(e);
                            }
                            onFootprintClick();
                          }
                        }}
                      >
                        {footprintLoading && (
                          <Box width="100%" position="absolute">
                            <Box width="100%" display="flex" justifyContent="center">
                              <CircularProgress />
                            </Box>
                          </Box>
                        )}
                        {t('Show')}
                      </button>
                    </div>
                  )}
              </>
            )}
            <>
              {(checkinStatus === 'started' || checkinStatus === 'expired') &&
                (guidancePages ?? []).length > 0 && (
                  <div className={styles['card__coupon']}>
                    <a
                      className={clsx(styles['btnSmall'], styles['btn--submit'])}
                      href={guidanceUrl}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        className={styles['btnSmall__coupon']}
                        src={
                          product?.qr_checkin_settings?.guidance_button_image_url ||
                          '/static/images/ic_coupon.svg'
                        }
                      />
                      {product?.qr_checkin_settings?.guidance_button_text?.replace(
                        /\u3000/g,
                        ' '
                      ) || t('Coupon')}
                    </a>
                  </div>
                )}
            </>

            <>
              {product?.qr_checkin_settings?.should_count_guests_for_checkin_with_guest_type && (
                <ETicketShareUrlButtons reservation={reservation} host={host} />
              )}
            </>

            <>
              {product?.qr_checkin_settings?.terms_of_service_url && (
                <a
                  className={clsx(styles['card__terms'])}
                  href={product?.qr_checkin_settings?.terms_of_service_url}
                  target="_blank"
                  rel="noreferrer"
                >
                  {t('Terms and Conditions')}
                  <img src="/static/images/ic_newwindow_blue.svg" />
                </a>
              )}
            </>

            {checkinStatus === 'not_started' && (
              <a
                className={clsx(
                  styles['btn'],
                  styles['btn--ac'],
                  !showDetails ? styles['is-open'] : styles['is-close']
                )}
                onClick={() => {
                  setShowDetails(!showDetails);
                }}
              >
                {showDetails ? <>{t('Close')}</> : <>{t('See details')}</>}
              </a>
            )}
          </div>
        </div>
      </section>
      <SpecialOfferTrigger reservation={reservation} />
      {showSpecialOffer && webSalesOffers && webSalesOffers.length > 0 && (
        <>
          <SpecialOfferPopup reservation={reservation} offer={webSalesOffers[0]} />
        </>
      )}
      <ETicketViewLogger reservation={reservation} />
    </>
  );
};

// show buy again button
const BuyAgainButton = ({ reservation }: { reservation: Reservation }) => {
  const { t } = useTranslation();
  const url = useUrl(`/products/${reservation.product_id}`);

  return (
    <div className={clsx(styles['card__acContent'], styles['is-active'])}>
      <ul className={clsx(styles['card__action'])}>
        <li className={clsx(styles['card__action__item'])}>
          <Link href={url} className={clsx(styles['btn'], styles['btn--submit'])}>
            {t('Purchase again')}
          </Link>
        </li>
      </ul>
    </div>
  );
};

// Show guests area
const Guest = ({ reservation }: { reservation: Reservation }) => {
  const { t } = useTranslation();
  const remainingGuestTypeKeys: string[] = [];
  const remainingGuestTypeText: { [index: string]: string } = {};
  reservation.guests.forEach((guest) => {
    if (!['大人', 'Adult', '子供', 'Child'].includes(guest.guest_type_key)) {
      if (!remainingGuestTypeKeys.includes(guest.guest_type_key)) {
        remainingGuestTypeKeys.push(guest.guest_type_key);
        remainingGuestTypeText[guest.guest_type_key] = guest.guest_type_title ?? '';
      }
    }
  });

  const numOfAdults = reservation.guests.filter((guest) =>
    ['大人', 'Adult'].includes(guest.guest_type_key)
  ).length;
  const numOfChildren = reservation.guests.filter((guest) =>
    ['子供', 'Child'].includes(guest.guest_type_key)
  ).length;

  return (
    <ul className={clsx(styles['card__member'])}>
      {numOfAdults > 0 && (
        <li className={clsx(styles['card__member__item'], numOfChildren ? null : styles['full'])}>
          <p className={clsx(styles['card__member__item__ttl'])}>{t('Adult')}</p>
          <p className={clsx(styles['card__member__item__num'])}>{numOfAdults}</p>
        </li>
      )}
      {numOfChildren > 0 && (
        <li className={clsx(styles['card__member__item'], numOfAdults ? null : styles['full'])}>
          <p className={clsx(styles['card__member__item__ttl'])}>{t('Child')}</p>
          <p className={clsx(styles['card__member__item__num'])}>{numOfChildren}</p>
        </li>
      )}

      {remainingGuestTypeKeys.map((guestTypeKey, idx) => (
        <li className={clsx(styles['card__member__item'], styles['full'])} key={idx}>
          <p className={clsx(styles['card__member__item__ttl'])}>
            {`${remainingGuestTypeText[guestTypeKey]} : ${getGuestCountByKey(
              reservation,
              guestTypeKey
            )}`}
          </p>
        </li>
      ))}
    </ul>
  );
};

// Show expiration date
const EquipmentBlockReference = ({ reservation }: { reservation: Reservation }) => {
  const { t } = useTranslation();

  return (
    <>
      {(reservation?.equipment_block_references ?? []).length > 0 && (
        <>
          <div className={clsx(styles['card__equipment-block-reference'])}>
            <p className={clsx(styles['card__equipment-block-reference__ttl'])}>{t('Seat')}</p>
            <div className={clsx(styles['card__equipment-block-reference__txt'])}>
              <p>
                <span>{(reservation.equipment_block_references || []).sort().join(' ')}</span>
              </p>
            </div>
          </div>
          {reservation?.equipment_instance_memo && (
            <div className={clsx(styles['card__equipment__memo'])}>
              <div className={clsx(styles['card__equipment__memo__txt'])}>
                <div>
                  <span>
                    {reservation?.equipment_instance_memo} <br />
                  </span>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

const EquipmentTitleBlockReference = ({ reservation }: { reservation: Reservation }) => {
  const { t } = useTranslation();

  const equipmentTitleBlockReferences = (reservation?.assigned_equipment_block_references ?? [])
    .reduce((acc, equipmentBlockReference) => {
      const equipment = acc.find(
        (e) => e?.equipment_title === equipmentBlockReference.equipment_title
      );
      if (equipment) {
        equipment.equipment_block_references.push(
          equipmentBlockReference.equipment_block_reference ?? ''
        );
      } else {
        acc.push({
          equipment_title: equipmentBlockReference.equipment_title ?? '',
          equipment_block_references: [equipmentBlockReference.equipment_block_reference ?? ''],
        });
      }
      return acc;
    }, [] as { equipment_title: string; equipment_block_references: string[] }[])
    .sort((a, b) => {
      return a.equipment_title.localeCompare(b.equipment_title);
    });

  return (
    <>
      {(reservation?.equipment_block_references ?? []).length > 0 && (
        <>
          <div className={clsx(styles['card__equipment-block-reference'])}>
            <p className={clsx(styles['card__equipment-block-reference__ttl'])}>{t('Seat')}</p>
            <div className={clsx(styles['card__equipment-block-reference__txt'])}>
              {equipmentTitleBlockReferences.map((equipmentTitleBlockReference, idx) => (
                <div key={idx} className={clsx(styles['resource'])}>
                  <span className={clsx(styles['block_reference'])}>
                    {equipmentTitleBlockReference.equipment_block_references.sort().join(' ')}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </>
  );
};

const ActionButtonsForMultipleCheckin = ({
  reservation,
  product,
  settings,
}: {
  reservation: Reservation;
  product: Product;
  settings: GetSettingsResponse;
}) => {
  const canceled = isReservationCanceled(reservation);
  const { isPackageETicket } = React.useContext(PackageETicketContext);

  return (
    <div className={clsx(styles['card__acContent'], styles['is-active'])}>
      <ul className={clsx(styles['card__action'])}>
        {getCheckinStubs(product).map((stub) => {
          return (
            <ETicketRedemptionCard
              reservation={reservation}
              product={product}
              stub={stub}
              key={stub.key}
            />
          );
        })}

        {canceled ? (
          <li className={clsx(styles['card__action__item'])}>
            <ReservationCanceledButton />
          </li>
        ) : (
          <>
            {isCancellableSetting(product, settings, reservation) && !isPackageETicket && (
              <li className={clsx(styles['card__action__item'])}>
                <CancelButton reservation={reservation} />
              </li>
            )}
          </>
        )}
      </ul>
    </div>
  );
};

// Show action buttons for single checkin reservation
const ActionButtons = ({
  reservation,
  product,
  settings,
}: {
  reservation: Reservation;
  product: Product;
  settings: GetSettingsResponse;
}) => {
  const canceled = isReservationCanceled(reservation);
  const notConfirm = isReservationNotConfirmed(reservation);
  const { isPackageETicket } = React.useContext(PackageETicketContext);

  const [redeemGuestTypeCounts, setRedeemGuestTypeCounts] = React.useState<GuestTypeCount[]>([]);
  const [redemptionCount, setRedemptionCount] = React.useState<number>(
    product?.qr_checkin_settings?.should_use_redemption_count ? 1 : 0
  );

  return (
    <div className={clsx(styles['card__acContent'], styles['is-active'])}>
      <ul className={clsx(styles['card__action'])}>
        {product?.qr_checkin_settings?.should_count_guests_for_checkin_with_guest_type && (
          <ETicketGuestCountSelector
            reservation={reservation}
            value={redeemGuestTypeCounts}
            onChange={(newGuestTypeCounts) => {
              setRedeemGuestTypeCounts(newGuestTypeCounts);
            }}
            isBg={false}
          />
        )}

        {product?.qr_checkin_settings?.should_use_redemption_count &&
          (product?.qr_checkin_settings?.preset_redemption_counts || []).length === 0 && (
            <ETicketRedemptionCounter
              count={redemptionCount}
              maxCount={product?.qr_checkin_settings?.max_redemption_count ?? 1}
              onChange={(count: number) => {
                setRedemptionCount(count);
              }}
              reservation={reservation}
            />
          )}

        {reservation?.checkin_info?.checkin_status !== 'CHECKED_IN' && (
          <>
            <li className={clsx(styles['card__action__item'])}>
              {notConfirm ? (
                <NotAvailableButton />
              ) : (
                <CheckinButton
                  reservation={reservation}
                  product={product}
                  guestTypeCounts={redeemGuestTypeCounts}
                  redemptionCount={redemptionCount}
                />
              )}
            </li>
            {canceled ? (
              <li className={clsx(styles['card__action__item'])}>
                <ReservationCanceledButton />
              </li>
            ) : (
              <>
                {isCancellableSetting(product, settings, reservation) && !isPackageETicket && (
                  <li className={clsx(styles['card__action__item'])}>
                    <CancelButton reservation={reservation} />
                  </li>
                )}
              </>
            )}
          </>
        )}
      </ul>
    </div>
  );
};

const CancelButton = ({ reservation }: { reservation: Reservation }) => {
  const { t } = useTranslation();
  const router = useRouter();
  const [inFlight, setInFlight] = React.useState<boolean>(false);

  const reservationId = reservation.id;
  const lastUpdatedDateTimeUtc = reservation.last_updated_date_time_utc;

  const url = useUrl(
    `/reservations/${reservationId}/cancel?ts=${lastUpdatedDateTimeUtc}&b=${router.asPath}`
  );

  return (
    <Link
      href={url}
      className={clsx(styles['btn'], styles['btn--cancel'])}
      onClick={() => {
        setInFlight(true);
      }}
    >
      {inFlight && (
        <Box width="100%" position="absolute">
          <Box width="100%" display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        </Box>
      )}
      {t('Cancel Reservation')}
    </Link>
  );
};

// Show Redemption date
const RedemptionDate = ({ reservation }: { reservation: Reservation; product: Product }) => {
  const { t, i18n } = useTranslation();

  return (
    <div className={clsx(styles['card__limit'])}>
      <p className={clsx(styles['card__limit__ttl'])}>{t('Redemption Date Time')}</p>
      <div className={clsx(styles['card__limit__txt'])}>
        {getCheckinTimeText(reservation, '', i18n.language)}
      </div>
    </div>
  );
};

const NotAvailableButton = () => {
  const { t } = useTranslation();

  return (
    <>
      <button
        className={clsx(styles['btn'], styles['width-128'], styles['btn--cancel'])}
        disabled={true}
      >
        <>{t('Not Available')}</>
      </button>
    </>
  );
};

const ReservationCanceledButton = () => {
  const { t } = useTranslation();

  return (
    <>
      <button className={clsx(styles['btn'], styles['btn--cancel'])} disabled={true}>
        <>{t('Canceled')}</>
      </button>
    </>
  );
};

const CheckinButton = ({
  reservation,
  product,
  stubKey,
  guestTypeCounts,
  redemptionCount,
}: {
  reservation: Reservation;
  product: Product;
  stubKey?: string;
  guestTypeCounts?: GuestTypeCount[];
  redemptionCount?: number;
}) => {
  const { t } = useTranslation();

  const { redeemTarget, setRedeemTarget } = React.useContext(ETicketRedeemTargetContext);

  let isDisabled = false;
  if (product?.qr_checkin_settings?.should_count_guests_for_checkin_with_guest_type) {
    isDisabled = guestTypeCounts?.reduce((acc, v) => acc + v.count, 0) === 0;
  } else if (product?.qr_checkin_settings?.should_use_redemption_count) {
    isDisabled = Number(redemptionCount) === 0;
  } else {
    isDisabled = Boolean(redeemTarget);
  }

  return (
    <>
      <button
        className={clsx(
          styles['btn'],
          styles['btn--redeem'],
          isDisabled ? styles['btn--disabled'] : styles['btn--submit']
        )}
        onClick={() =>
          setRedeemTarget({
            reservation: reservation,
            stubKey: stubKey ?? '',
            guestTypeCounts: guestTypeCounts ?? [],
            redemptionCount: redemptionCount ?? 0,
          })
        }
        disabled={isDisabled}
        style={{
          ...(product?.qr_checkin_settings?.redemption_button_color
            ? { backgroundColor: product?.qr_checkin_settings?.redemption_button_color }
            : {}),
          ...(product?.qr_checkin_settings?.redemption_button_text_color
            ? { color: product?.qr_checkin_settings?.redemption_button_text_color }
            : {}),
        }}
      >
        {product?.qr_checkin_settings?.redemption_button_text
          ? formatTextWithLineBreaks(product?.qr_checkin_settings?.redemption_button_text)
          : t('Redeem')}
      </button>
    </>
  );
};

const getGuestCountByKey = (reservation: Reservation, key: string): number => {
  return reservation.guests.filter((guest) => {
    return guest.guest_type_key === key;
  }).length;
};

const getCheckinStubs = (product: Product) => {
  return product?.qr_checkin_settings?.stubs ?? [];
};
