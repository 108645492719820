import { WithTranslation } from 'react-i18next';

import { Reservation } from 'models/reservation';

export const getGuestNameText = (
  reservation: Reservation | null,
  t: WithTranslation['t']
): string => {
  const givenName =
    reservation?.field_responses.find((response) => response.key === 'given_name')?.response ??
    reservation?.field_responses.find((response) => response.key === 'kana_given_name')?.response ??
    '';
  const familyName =
    reservation?.field_responses.find((response) => response.key === 'family_name')?.response ??
    reservation?.field_responses.find((response) => response.key === 'kana_family_name')
      ?.response ??
    '';

  return t('{{guestGivenName}} {{guestFamilyName}}', {
    guestGivenName: givenName,
    guestFamilyName: familyName,
  });
};
