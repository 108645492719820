import * as React from 'react';
import {
  selectApplicableEmailSalesOffers,
  triggerServerSideSalesOffer,
} from 'ducks/client/salesOffers';
import { useDispatch, useSelector } from 'react-redux';
import { Reservation } from 'models/reservation';
import { ApiKeyContext } from 'contexts/ApiKeyContext';

interface Props {
  reservation: Reservation | null;
}

export const SpecialOfferTrigger = ({ reservation }: Props) => {
  const emailSpecialOffers = useSelector(selectApplicableEmailSalesOffers);
  const dispatch = useDispatch();
  const { apiKey } = React.useContext(ApiKeyContext);

  React.useEffect(() => {
    if (emailSpecialOffers.length && reservation?.id) {
      const offer = emailSpecialOffers[0];

      dispatch(triggerServerSideSalesOffer(apiKey, reservation?.id, offer?.id));
    }
  }, [emailSpecialOffers, reservation?.id, dispatch, apiKey]);

  return null;
};
