import * as React from 'react';
import { ReduxState } from 'ducks';
import { throttle } from 'lodash';
import { useSelector } from 'react-redux';

import { logCustomerEventWithNoSideEffects } from 'ducks/client/customerEvents';
import { ApiKeyContext } from 'contexts/ApiKeyContext';
import { useCustomerEventLoggingEnabled } from 'hooks/useCustomerEventLoggingEnabled';
import { Reservation } from 'models/reservation';
import { CurrentPositionContext } from 'contexts/CurrentPositionContext';

export interface Props {
  reservation: Reservation;
}

const ETicketViewLoggerComponent = ({ reservation }: Props) => {
  const { apiKey } = React.useContext(ApiKeyContext);

  const { currentPosition } = React.useContext(CurrentPositionContext);
  const visitorToken = useSelector((state: ReduxState) => state.tracking.visitorToken);
  const customerEventLoggingEnabled = useCustomerEventLoggingEnabled();
  const customerId = useSelector((state: ReduxState) => state.customer.customer?.id);

  const debouncedLogCustomerEvent = throttle(
    (params: { apiKey: string; visitorToken: string; reservationId: string; customerId: string }) =>
      logCustomerEventWithNoSideEffects({
        apiKey: params.apiKey,
        eventType: 'eticket-view',
        visitorToken: params.visitorToken,
        reservationId: params.reservationId,
        metadata: JSON.stringify({
          lat: currentPosition?.lat(),
          lng: currentPosition?.lng(),
          site: 'onsite',
        }),
        customerId: params.customerId,
      }),
    5000,
    {
      leading: true,
    }
  );

  React.useEffect(() => {
    if (apiKey && visitorToken && customerEventLoggingEnabled && reservation?.id) {
      debouncedLogCustomerEvent({
        apiKey,
        visitorToken,
        reservationId: reservation?.id,
        customerId: customerId ?? '',
      });
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [apiKey, visitorToken, customerEventLoggingEnabled, customerId]);

  return null;
};

export const ETicketViewLogger = React.memo(ETicketViewLoggerComponent);
